import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import Message from 'uikit/Message/Message';
import { loginToAnotherAccount } from 'utils/api';
import { setCookie } from 'utils/helper';
import { bookmarkListSelector } from 'containers/Bookmark/atoms/bookmarkAtoms';
import { sidePanelCompanyListAtom } from 'components/SidePanel/helpers/sidePanelhelper';
import { fetchClient } from 'utils/api-client';
import { useHistory } from 'utils/router';
import { LoginToAnotherAccountResponseType } from './dashboard.types';
import {
  SESSION_EXPIRY_DAYS,
  COMPANY_NAME,
  TOKEN,
  SELECTED_COMPANY,
} from '../../../app-constants';
import { SWITCH_COMPANY_ACTION } from './dashboard-actions';
import { companyNameAtom } from './stoken-atom';

export const switchCompanyAtom = atom({
  key: SWITCH_COMPANY_ACTION,
  default: '',
});

type LoginToAnotherCompanyParamType = {
  tenantId: string;
  handleNavigate?: () => void;
};

type handleSwitchCompanyParams = {
  access: number;
  companyName?: string;
} & LoginToAnotherCompanyParamType;

export const loginToAnotherCompany = async ({
  tenantId,
  handleNavigate,
}: LoginToAnotherCompanyParamType) => {
  try {
    const result: LoginToAnotherAccountResponseType = await fetchClient(
      'normal',
    ).get(loginToAnotherAccount(tenantId));
    if (result.data && result.data.status === 'success') {
      setCookie(TOKEN, result.data.token, SESSION_EXPIRY_DAYS);
      setCookie(COMPANY_NAME, result.data.company, SESSION_EXPIRY_DAYS);
      setCookie(SELECTED_COMPANY, tenantId, SESSION_EXPIRY_DAYS);
      if (typeof handleNavigate === 'function') {
        handleNavigate();
      }
    }
    return { ...result.data };
  } catch (error) {
    const typedError = error as Error;
    const errorMessage = typedError.toString();
    throw new Error(errorMessage);
  }
};

export const useOnSwitchCompany = () => {
  const setSwitchCompany = useSetRecoilState(switchCompanyAtom);
  const setCompanyName = useSetRecoilState(companyNameAtom);
  const { companyListSideMenu } = useRecoilValue(bookmarkListSelector);
  const setSidePanelCompanyList = useSetRecoilState(sidePanelCompanyListAtom);
  const history = useHistory();
  const onSwitchCompany = async ({
    access,
    tenantId,
    handleNavigate,
    companyName,
  }: handleSwitchCompanyParams) => {
    try {
      if (access === 2) {
        const foundList = companyListSideMenu.find(
          (company) => company.tenantId === tenantId,
        );

        if (typeof foundList === 'undefined' && companyName) {
          setSidePanelCompanyList([
            ...companyListSideMenu,
            { companyName, tenantId },
          ]);
        }

        const switchCompanyResponse = await loginToAnotherCompany({
          tenantId,
          handleNavigate,
        });
        setCompanyName(switchCompanyResponse.company);
        setSwitchCompany(tenantId);
      }
    } catch (error) {
      const typedError = error as Error;
      if (typedError.message.includes('401')) {
        history.push('/login');
      }
      Message.error(typedError.message);
    }
  };
  return {
    onSwitchCompany,
  };
};
