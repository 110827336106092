import * as React from 'react';
import { useGetIconSize } from './icon-hooks';
import { IconType, IconColorType } from './icons.types';

type Props = {
  size: IconType;
  color: IconColorType;
};

const defaultProps: Props = {
  size: 'medium',
  color: 'currentColor',
};

function SvgRefresh({ size, color }: Props) {
  const { iconSize, iconColor } = useGetIconSize(size, color);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      width={iconSize}
      height={iconSize}
      fill={iconColor}
    >
      <path d="M481.539-180.001q-124.625 0-212.312-87.67-87.687-87.669-87.687-212.268t87.687-212.329q87.687-87.731 212.312-87.731 81.922 0 145.153 35.654 63.231 35.654 106.384 97.578v-110.54q0-9.903 6.398-16.297 6.398-6.395 16.308-6.395 9.909 0 16.294 6.395 6.384 6.394 6.384 16.297v173.999q0 12.519-8.163 20.682-8.164 8.164-20.683 8.164H575.615q-9.903 0-16.297-6.398t-6.394-16.307q0-9.91 6.394-16.294 6.394-6.385 16.297-6.385h135.308q-36.077-60.769-94.692-97.769-58.616-37-134.692-37-106.693 0-180.654 73.961Q226.924-586.692 226.924-480t73.961 180.654q73.961 73.961 180.654 73.961 74.81 0 138.943-40.269 64.133-40.27 94.979-108.039 3.462-8.692 12.269-13.038 8.808-4.346 17.426-.5 9.381 3.846 12.035 12.846t-.807 18.307Q720.461-275.54 646.19-227.771q-74.27 47.77-164.651 47.77Z" />
    </svg>
  );
}
SvgRefresh.defaultProps = defaultProps;
export default SvgRefresh;
