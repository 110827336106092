/* eslint-disable no-undef */

export const configs = {
  env:
    typeof process.env.APP_ENV !== 'undefined'
      ? process.env.APP_ENV
      : 'production',
  appType:
    typeof process.env.APP_TYPE !== 'undefined'
      ? process.env.APP_TYPE
      : 'turbo',
  output: {
    buildPath: '/',
  },
  applicationName: 'TurboComply',
  showRoles: true,
  showLogo: true,
  showTimeSheet: true,
  showVault: true,
  showProforma: true,
  showRuleEngine: true,
  showRegistries: process.env.APP_ENV !== 'production',
  showWorkFlow: true,
  showKuwait: process.env.APP_ENV !== 'production',
  jarvis_url:
    typeof process.env.API_URL_BE !== 'undefined'
      ? process.env.API_URL_BE
      : 'https://api.turbocomply.in',
  platformHostUrl:
    typeof process.env.API_URL_PLATFORM !== 'undefined'
      ? process.env.API_URL_PLATFORM
      : 'https://platform.turbocomply.in',
};
