import type { MyProfileResponse } from './account-settings.types';

export const COMPANY_PROFILE_SELECTOR = 'accounts/COMPANY_PROFILE_SELECTOR';
export const MY_PROFILE_SELECTOR = 'accounts/MY_PROFILE_SELECTOR';
export const PROFILE_REFETCH = 'accounts/PROFILE_REFETCH';
export const REGISTER_BRANCH_REFRESH = 'accounts/REGISTER_BRANCH_REFRESH_ATOM';
export const PERMISSIONS_ATOM = 'accounts/PERMISSIONS_ATOM';
export const MYCOMPANY_SELECTOR = 'accounts/MYCOMPANY_SELECTOR';
export const COMPANY_INFORMATION_REFETCH_ATOM =
  'accounts/COMPANY_INFORMATION_REFETCH_ATOM';
export const profileDataTemp: MyProfileResponse = {
  my_profile: {
    type: '',
    user_id: '',
    dob: '',
    email: '',
    first_name: '',
    last_name: '',
    country_code: '',
  },
  permissions: {
    names: [],
    tenants: [],
  },
  subscription: {},
  status: '',
};
