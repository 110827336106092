import React from 'react';
import Flex from 'uikit/Flex/Flex';
import SuspenseLoader from 'uikit/SuspenseLoader/suspense-loader';
import { VerticleBorder } from './verticle-border';
import { LoginForm } from './login-form';
import { LoginCard } from './login-card';
import { GoogleLogin } from './google-login';

export function Login() {
  return (
    <LoginCard title="Login" registrationLink="/register">
      <Flex row between>
        <SuspenseLoader>
          <GoogleLogin />
        </SuspenseLoader>
        <VerticleBorder />
        <LoginForm />
      </Flex>
    </LoginCard>
  );
}
