export const STORE_USER_DETAILS = 'STORE_USER_DETAILS';

export const STORE_COMPANY_DETAILS = 'STORE_COMPANY_DETAILS';

export const STORE_LOCATIONS = 'STORE_LOCATIONS';

export const STORE_USER_ROLES = 'STORE_USER_ROLES';

export const USER_CREATED = 'USER_CREATED';

export const STORE_ALL_USERS = 'STORE_ALL_USERS';

export const STORE_AL_LOCATIONS = 'STORE_AL_LOCATIONS';

export const STORE_PROFILE_DETAILS = 'STORE_PROFILE_DETAILS';

export const AS_STORE_SUB_CATEGORIES = 'AS_STORE_SUB_CATEGORIES';

export const GET_PENDING_ACCESS = 'GET_PENDING_ACCESS';
