export const white = '#ffffff';
export const icon_black = '#212529';
export const icon_gray = '#61656F';
export const icon_light_gray = '#AFAFAF';
export const icon_dull_gray = '#E6E6E6';
export const icon_white = '#FFFFFE';
export const icon_yellow_dark = '#EEB209';
export const icon_red_dark = '#DF4440';
export const dark_red = '#E7332D';
export const semantic_red = '#EC5B56';
export const light_red = '#F5ADAB';
export const dark_orange = '#AA370D';
export const semantic_orange = '#EE5A24';
export const light_orange = '#F4916D';
export const dark_green = '#1A7440';
export const semantic_green = '#27AE60';
export const light_green = '#5DDB92';
export const lightest_red = '#ffb7b84d';
export const icon_blue = 'blue';
export const icon_red_material = '#fb3f3f';
export const icon_yellow = '#FFD215';
export const link = '#4562F8';
export const gray_1 = icon_dull_gray;
export const gray_2 = '#E5E4E2';
export const table_gray = '#FAFAFA';
export const lightest_gray = '#f8f8f8';
export const border_color = '#f0f0f0';
export const icon_orange = '#fde3cf';
export const orange = '#f56a00';
export const button_active = '#2E3345';
export const icon_green = '#117a11';
export const green_1 = '#27AE60';
export const primary_blue_2 = '#448EF7';
export const primary_blue = '#456bd9';
export const icon_purple = '#800080';
export const grey_2 = '#eff2f5';
export const primary_light = 'rgb(69 98 248 / 25%)';
export const z_index_5 = 5;
export const z_index_10 = 10;
export const space_1 = '0.25rem';
export const spacemicro_0_5 = '0.125rem';
export const spacemicro_1_5 = '0.375rem';
export const spacemicro_2_5 = '0.625rem';
export const spacemicro_3_5 = '0.875rem';
export const space_2 = '0.5rem';
export const space_3 = '0.75rem';
export const space_4 = '1rem';
export const space_5 = '1.25rem';
export const space_6 = '1.5rem';
export const space_7 = '1.75rem';
export const space_8 = '2rem';
export const space_9 = '2.25rem';
export const space_10 = '2.5rem';
export const space_12 = '3rem';
export const border_radius = '6px';
export const border_radius_large = '30px';
export const font_size_small = '0.8125rem';
export const red = '#EC5B56';
export const box_shadow = '0px 2px 6px #0000003b';
