import { fromJS } from 'immutable';
import { GET_MY_LOCATION, STORE_ALL_NEW_LOCATION } from './constant';

const initialState = fromJS({
  newLocation: [],
});

export default function LocationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MY_LOCATION:
      return state.merge({ pageLoader: false });
    case STORE_ALL_NEW_LOCATION:
      return state.merge({ newLocation: action.data, pageLoader: false });
    default:
      return state;
  }
}
