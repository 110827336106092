export const USER_REGISTERED = 'USER_REGISTERED';

export const REGISTER_USER = 'REGISTER_USER';
export const REQUEST_ERROR = 'REQUEST_ERROR';

export const STORE_EMUNS = 'STORE_EMUNS';
export const GET_ENUMS = 'GET_ENUMS';

export const STORE_COMPANY_DETAILS = 'STORE_COMPANY_DETAILS';
export const GET_COMPANIES_DETAILS = 'GET_COMPANIES_DETAILS';

export const USER_CREDENTIAL = 'USER_CREDENTIAL';
