import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import { useSetRecoilState } from 'recoil';
import { fetchClient } from 'utils/api-client';
import { useLocation, useHistory } from 'react-router-dom';
import { stokenAtom } from 'containers/CompanyList/atoms/stoken-atom';
import message from 'uikit/Message/Message';
import {
  AnimationWrapper,
  useSaveAnimationHook,
} from 'uikit/save-animation/save-animation';
import { Flex } from '@turbocomplynpm/flex';
import { AuthLoginAnimation } from '@turbocomplynpm/auth-login';
import { configs } from 'server-url';
import { AppDispatch, RootState } from 'configureStore';
import { fetchGoogleCallCallbackApi } from '../../utils/api';
import { setCookie, setSession } from '../../utils/helper';
import { USER_CREDENTIAL } from '../CompanyDetails/constants';
import {
  SESSION_EXPIRY_DAYS,
  TOKEN,
  QUERY_PARAMS_LOGIN_STEP,
  SUPER_TOKEN,
  FIRM_TENANT_ID,
} from '../../app-constants';
import * as styles from './google-auth.module.css';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => parse(search), [search]);
}

const cx = classNames.bind(styles);

type Props = {
  location: {
    search: string;
  };
  authenticated: boolean;
  dispatch: AppDispatch;
};

function GoogleAuth(props: Readonly<Props>) {
  const history = useHistory();
  const query = useQuery();
  const setStoken = useSetRecoilState(stokenAtom);

  const urlCode = typeof query.code === 'string' ? query.code : '';
  const code = decodeURIComponent(decodeURIComponent(urlCode));
  const { location: { search = '' } = {}, authenticated, dispatch } = props;

  const { showAnimation, segments, onLoopComplete, setLoadingState } =
    useSaveAnimationHook({
      segmentOptions: {
        idle: [0, 0],
        pending: [0, 100],
        fulfilled: [100, 145],
        rejected: [100, 0],
      },
    });
  const updateInfo = useCallback(
    (data: { email: string }) =>
      dispatch({
        type: USER_CREDENTIAL,
        userCredentials: data,
      }),
    [dispatch],
  );

  const handleSignInFlow = () => {
    setLoadingState('pending');
    fetchClient('normal')
      .get(fetchGoogleCallCallbackApi(), {
        params: {
          code,
        },
      })
      .then((response) => {
        if (response.data.status === 'success') {
          setLoadingState('fulfilled');
          setStoken(response.data.token);
          setCookie(SUPER_TOKEN, response.data.token, SESSION_EXPIRY_DAYS);
          if (response.data.code) {
            updateInfo({ email: response.data.email });
            localStorage.setItem('email', response.data.email);
            message.success(
              `You have successfully registered and welcome to ${configs.applicationName}`,
            );
            history.replace(
              `/register?step=${QUERY_PARAMS_LOGIN_STEP}&email=${response.data.email}`,
            );
          } else if (!response.data.tenant_id) {
            updateInfo({ email: response.data.email });
            localStorage.setItem('email', response.data.email);
            history.replace(
              `/register?step=${QUERY_PARAMS_LOGIN_STEP}&email=${response.data.email}`,
            );
          } else {
            const { tenant_id: tenantId, token, role } = response.data;
            setCookie(TOKEN, tenantId, SESSION_EXPIRY_DAYS);
            localStorage.setItem('email', response.data.email);
            setCookie(FIRM_TENANT_ID, tenantId, SESSION_EXPIRY_DAYS);
            setSession({
              token,
              role,
              tenantId,
            });
            history.replace(`/company-list`);
            message.success(
              `You have successfully logged in to ${configs.applicationName}`,
            );
          }
        } else {
          setLoadingState('rejected');
          history.replace(`/register`);
          message.error(
            'First you need to register with us to use our services',
          );
        }
      })
      .catch((_error) => {
        setLoadingState('rejected');
      });
  };

  useEffect(() => {
    handleSignInFlow();
  }, []);

  useEffect(() => {
    if (authenticated) {
      setLoadingState('fulfilled');
      const refUrl = parse(search);
      if (typeof refUrl.refUrl === 'string') {
        history.replace(refUrl.refUrl);
      } else {
        history.replace('/company-list');
      }
    }
  }, [code, search, authenticated, setLoadingState, handleSignInFlow, history]);

  if (showAnimation) {
    return (
      <Flex flex="1" className={cx('login-form')}>
        <AnimationWrapper className={cx('login-form__animation-wrapper')}>
          <AuthLoginAnimation
            segments={segments}
            onLoopComplete={onLoopComplete}
          />
        </AnimationWrapper>
      </Flex>
    );
  }

  return (
    <div
      style={{
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <h4>Authenticating, Please wait...</h4>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  authenticated: state.get('auth').get('authenticated'),
});

export default connect(mapStateToProps)(GoogleAuth);
