import { COUNTRY_CODE } from 'app-constants';
import { LocationType } from './account-settings.types';

interface PayloadTypes<T> {
  payload: T[];
  keys: (keyof T)[];
  constant: T;
}

interface PayloadValueTypes {
  payload: string | number | boolean;
  propertyType: string | number;
}

export const companyConstant = {
  name: '',
  place_of_roc: '',
  cin: '',
  esi: '',
  pan: '',
  tan: '',
  gst: '',
  pf: '',
  id: '',
  company_type: 0,
  industry_vertical: 0,
  company_domain: 0,
  active_from: '',
  status: 0,
  industry: '',
  date_of_incorporation: undefined,
  directors: [],
  tenant_id: undefined,
  base_branch_id: undefined,
  firm_type: 0,
};
export const branchConst: LocationType = {
  id: '',
  address1: '',
  address2: '',
  branch_type: 0,
  city: '',
  country: '',
  name: '',
  phone: '',
  state: '',
  zipcode: '',
  email: '',
  countryCode: '',
  company_id: '',
  is_hq: false,
  is_active: false,
  gst: '',
  country_code: COUNTRY_CODE.IN,
};

export const formatPayloadWithoutNullValues = <T>({
  payload,
  keys,
  constant,
}: PayloadTypes<T>): T[] => {
  const initialPayload = [...payload];
  return initialPayload.map((data) => {
    const intialData = { ...data };
    keys.forEach((key) => {
      if (!data[key]) {
        intialData[key] = constant[key];
      }
    });
    return intialData;
  });
};

export const formatCompanyPayloadValue = ({
  payload,
  propertyType,
}: PayloadValueTypes) => {
  if (!payload && typeof propertyType === 'string') {
    return '';
  }
  if (!payload && typeof propertyType === 'number') {
    return 0;
  }
  return payload;
};
