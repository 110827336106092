import { CreateUserManagement } from 'containers/UserManagement/atoms/user-management-types';

export const REQUIRED_FIELD = {
  required: 'This field is required',
};
export const REGEX_PHONE_NUMBER =
  /(^(?!(91|0))(\d{10}))|(^91\d{10})|(^0\d{10})/;
export const REGEX_GST_IN =
  /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[CZ]{1}[A-Z\d]{1}/;

export const PASSWORD =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export type IsEmptyValueType = string | number | undefined | null | boolean;

export const isEmpty = <T>(value: T): boolean =>
  value === undefined || value === null || value === '';

export const isValidEmail = (value: string) => {
  if (typeof value === 'string') {
    return (
      value
        .trim()
        .match(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z.])+$/) !== null
    );
  }
  return false;
};

export const isValidPassword = (value: string) => {
  if (typeof value === 'string') {
    return PASSWORD.test(value);
  }
  return false;
};

export const passwordValidation = (value: string) => {
  if (isEmpty(value)) {
    return 'This field is required';
  }
  if (!isValidPassword(value)) {
    return 'Password must contain at least 8 characters, including UPPER/lowercase and special characters';
  }
  return true;
};

export const PASSWORD_RULES = {
  ...REQUIRED_FIELD,
  validate: passwordValidation,
};

const phoneValidation: Record<string, RegExp> = {
  IN: /^[6789]\d{9}$/,
  AE: /^(?:50|52|54|55|56|57|58)[0-9]{7}$/,
  KW: /^[569]\d{7}$/,
};

export const validatePhone = (
  phone: string,
  users: CreateUserManagement,
): string | boolean => {
  if (isEmpty(phone)) {
    return true;
  }
  if (!isEmpty(phone) && isEmpty(users.country_code)) {
    return 'Select country';
  }
  if (!phoneValidation[users.country_code].test(phone)) {
    return 'Not a valid phone number';
  }
  return true;
};
