import { fromJS } from 'immutable';
import {
  STORE_USER_DETAILS,
  STORE_COMPANY_DETAILS,
  STORE_LOCATIONS,
  STORE_USER_ROLES,
  STORE_ALL_USERS,
  STORE_AL_LOCATIONS,
  STORE_PROFILE_DETAILS,
  AS_STORE_SUB_CATEGORIES,
  GET_PENDING_ACCESS,
} from './constants';

const initialState = fromJS({
  auctions: [],
  count: 0,
  userRoles: [],
  users: [],
  locations: [],
  companyDetails: {},
  profileDetails: {},
  sub_categories: [],
  pending_access_list: [],
});

export default function accountSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_PROFILE_DETAILS:
      return state.merge({ profileDetails: action.data });
    case STORE_USER_ROLES:
      return state.merge({ userRoles: action.data });
    case STORE_USER_DETAILS:
      return state.merge({
        userDetails: action.userDetails,
      });
    case STORE_COMPANY_DETAILS:
      return state.merge({
        companyDetails: action.data,
        companyBranches: action.branches,
      });
    case STORE_ALL_USERS:
      return state.merge({ users: action.data });
    case STORE_AL_LOCATIONS:
      return state.merge({ locations: action.data });
    case STORE_LOCATIONS:
      return state.merge({ locations: action.data });
    case AS_STORE_SUB_CATEGORIES:
      return state.merge({ sub_categories: action.data });
    case GET_PENDING_ACCESS:
      return state.merge({ pending_access_list: action.data });
    default:
      return state;
  }
}
