export const STORE_SUB_CATEGORIES = 'STORE_SUB_CATEGORIES';

export const STORE_COMPLIANCES = 'STORE_COMPLIANCES';

export const REMOVE_COMPLIANCES = 'REMOVE_COMPLIANCES';

export const STORE_TASKS = 'STORE_TASKS';

export const GET_SUB_CATEGORIES = 'GET_SUB_CATEGORIES';

export const STORE_DATA_ROOM_DETAILS = 'STORE_DATA_ROOM_DETAILS';

export const STORE_DATA_ROOM_FILES = 'STORE_DATA_ROOM_FILES';

export const STORE_ALL_NEW_TAXATION = 'STORE_ALL_NEW_TAXATION';

export const GET_ALL_NEW_TAXATION = 'GET_ALL_NEW_TAXATION';

export const STORE_CATEGORY_WISE_REPO = 'STORE_CATEGORY_WISE_REPO';

export const STORE_DATA_CATEGORY_ROOM_FILES = 'STORE_DATA_CATEGORY_ROOM_FILES';

export const UPLOAD_REPO_SUCCESS = 'UPLOAD_REPO_SUCCESS';

export const STORE_DATA_CATEGORY_ROOM_ALL_FILES =
  'STORE_DATA_CATEGORY_ROOM_ALL_FILES';

export const STORE_DATA_ROOM_FOLDERS = 'STORE_DATA_ROOM_FOLDERS';

export const STORE_DATA_CATEGORY_ROOM_PERSONAL_FILES =
  'STORE_DATA_CATEGORY_ROOM_PERSONAL_FILES';

export const UPLOAD_STARTED = 'UPLOAD_STARTED';

export const UPLOAD_COMPLETED = 'UPLOAD_COMPLETED';

export const UPLOAD_FAILED = 'UPLOAD_FAILED';
