import React from 'react';
import SkeletonLoader from 'uikit/SkeletonLoader/skeleton-loader';
import VaultSaveContainerLoader from 'vault/vault-loaders/vault-save-container-loader';
import { LoaderTypes } from './suspense-loader-types';
import SpinnerLoader from './spinner-loader';

type Props = {
  loaderType: LoaderTypes;
  loaderClass?: string;
  testId?: string;
};
function LoaderWidget({ loaderType, loaderClass, testId }: Props) {
  switch (loaderType) {
    case 'spinner':
      return <SpinnerLoader testId={testId} />;
    case 'vaultSave':
      return <VaultSaveContainerLoader />;
    default:
      return <SkeletonLoader className={loaderClass} testId={testId} />;
  }
}

export default LoaderWidget;
