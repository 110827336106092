import type { PermissionType } from "./authorize-types";

export const ADMIN_PERMISSION = 'all:all:all';
export const VAULT_PERMISSIONS = [
  'express:users:add',
  'express:users:update',
  'express:users:delete',
];

export const ALL_TASKS_PERMISSION: PermissionType = 'elixir:tasks:all-tasks';
export const MY_TASKS_PERMISSION: PermissionType = 'elixir:tasks:my-tasks';