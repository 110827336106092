import { fromJS } from 'immutable';
import { STORE_TASK_DETAILS, CLOSE_TASK_OVERLAY } from './constants';

const initialState = fromJS({
  taskDetails: null,
  visible: false,
});

export default function taskViewReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_TASK_DETAILS:
      return state.merge({ taskDetails: action.data, visible: true });
    case CLOSE_TASK_OVERLAY:
      return state.merge({ visible: false });
    default:
      return state;
  }
}
