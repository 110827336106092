import React, { type ReactNode } from 'react';
import { Checkbox as CheckboxComponent } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxFormProps } from 'uikit/InputText/InputFormTypes';
import type { FieldValues } from 'react-hook-form';

type Props<T extends FieldValues> = {
  onChange?: (e: CheckboxChangeEvent) => void;
  name: string;
  children: ReactNode;
  checked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  className?: string;
} & CheckboxFormProps<T>;

function Checkbox<T extends FieldValues>({
  name,
  checked,
  indeterminate,
  disabled,
  className,
  children,
  ...rest
}: Props<T>) {
  const value = 'value' in rest ? rest.value : undefined;
  const field = 'field' in rest ? rest.field : {};

  let onChange = (_e: CheckboxChangeEvent) => {};
  if ('onChange' in rest && typeof rest.onChange === 'function') {
    onChange = rest.onChange;
  } else if ('field' in rest && 'onChange' in field) {
    onChange = field.onChange as (e: CheckboxChangeEvent) => void;
  }

  let isChecked = checked;
  if ('value' in field) {
    isChecked = field.value === true;
  }
  return (
    <CheckboxComponent
      value={value}
      onChange={onChange}
      name={name}
      indeterminate={indeterminate}
      checked={isChecked}
      disabled={disabled}
      className={className}
      {...field}
    >
      {children}
    </CheckboxComponent>
  );
}

export type { CheckboxChangeEvent };

export default Checkbox;
