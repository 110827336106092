/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { fromJS } from 'immutable';
import history from './utils/history';
import languageProviderReducer from './containers/LanguageProvider/reducer';
import authReducer from './containers/LoginPage/reducer';
import {
  CHECKING_SESSION,
  STORE_NOTIFICATIONS_DATA,
  STORE_PARENT_CATEGORIES,
  STORE_EMUNS,
  GET_PARENT_CATEGORIES,
  GET_ENUMS,
} from './app-constants';
import accountSettingsReducer from './containers/account-settings/reducer';
import categoriesReducer from './containers/Categories/reducer';
import taskViewReducer from './containers/TaskView/reducer';
import adminHomeReducer from './containers/Admin/Home/reducer';
import registrationReducer from './containers/Registration/reducer';
import { signUpReducer, verifyUserReducer } from './containers/Signup/reducer';
import dashboardDetailsReducer from './containers/Dashboard/reducer';
import companyDetailsReducer from './containers/CompanyDetails/reducer';
import EventReducer from './containers/Events/reducer';
import LocationReducer from './containers/Help/reducer';
import companyListReducer from './containers/CompanyList/reducer';
import userCredentialReducer from './containers/CompanyDetails/CredReducer';
import tasklistreducer from 'TasksListing/store/tasklistreducer';

// Notifications Reducer
const notificationsInitialState = fromJS({
  notifications: [],
  count: 0,
});

export function notifications(state = notificationsInitialState, action = {}) {
  if (action.type === STORE_NOTIFICATIONS_DATA) {
    return state.merge({ notifications: action.data, count: action.count });
  }
  return state;
}

// Page Loader Reducer
const pageLoadersInitialState = fromJS({
  loading: false,
});

export function pageLoader(state = pageLoadersInitialState, action = {}) {
  console.log('action', action);
  return state;
}

// Is Mobile view
const isMobileInitialState = fromJS({
  isMobile: false,
});

export function isMobile(state = isMobileInitialState, action = {}) {
  if (action.type === 'SET_IS_MOBILE') {
    return state.merge({ isMobile: true });
  }
  return state;
}

const enumsInitialState = fromJS({
  enums: {},
});

export function enums(state = enumsInitialState, action = {}) {
  if (action.type === STORE_EMUNS) {
    return state.merge({ enums: action.enums });
  }

  return state;
}

const parentCategoriesInitialState = fromJS({
  items: [],
});

export function parentCategories(
  state = parentCategoriesInitialState,
  action = {},
) {
  if (action.type === STORE_PARENT_CATEGORIES) {
    return state.merge({ items: action.items });
  }
  return state;
}

export function appLoader(state = fromJS({ appLoader: true }), action = {}) {
  switch (action.type) {
    case GET_PARENT_CATEGORIES:
      return state.merge({ appLoader: true });
    case STORE_PARENT_CATEGORIES:
      return state.merge({ appLoader: false });
    case GET_ENUMS:
      return state.merge({ appLoader: true });
    case STORE_EMUNS:
      return state.merge({ appLoader: false });
    default:
      return state;
  }
}

const btnLoadersInitialState = fromJS({
  loading: false,
  checkingSession: true,
  logIn: false,
  register: false,
  forgotPassword: false,
  resetPassword: false,
});

export function loader(state = btnLoadersInitialState, action = {}) {
  switch (action.type) {
    case CHECKING_SESSION:
      return state.merge({ checkingSession: true });
    case 'LOG_IN':
      return state.merge({ logIn: true });
    case 'LOGGED_IN':
      return state.merge({ logIn: false });
    case 'REGISTER_USER':
      return state.merge({ register: true });
    case 'USER_REGISTERED':
      return state.merge({ register: false });
    case 'FORGOT_PASSWORD':
      return state.merge({ forgotPassword: true });
    case 'RESET_LINK_SENT':
      return state.merge({ forgotPassword: false });
    case 'RESET_PASSWORD':
      return state.merge({ resetPassword: true });
    case 'RESET_SUCCESSFUL':
      return state.merge({ resetPassword: false });
    case 'REQUEST_ERROR':
      return state.merge({
        loading: false,
        logIn: false,
        register: false,
        forgotPassword: false,
        resetPassword: false,
      });
    default:
      return state;
  }
}

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    language: languageProviderReducer,
    auth: authReducer,
    userCredentialReducer,
    loader,
    pageLoader,
    notifications,
    adminHome: adminHomeReducer,
    events: EventReducer,
    locations: LocationReducer,
    companyList: companyListReducer,
    accountSettings: accountSettingsReducer,
    categories: categoriesReducer,
    parentCategories,
    registration: registrationReducer,
    signUp: signUpReducer,
    verifyUser: verifyUserReducer,
    companyDetails: companyDetailsReducer,
    dashboardDetails: dashboardDetailsReducer,
    isMobile,
    enums,
    appLoader,
    taskView: taskViewReducer,
    ...tasklistreducer,
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  const mergeWithRouterState = connectRouter(history);
  return mergeWithRouterState(rootReducer);
}
