import { CallbackInterface, selector } from 'recoil';
import { fetchClient } from 'utils/api-client';
import { getGoogleLoginUrlApi, loginApi } from 'utils/api';
import { setCookie, setSession } from 'utils/helper';
import { FIRM_TENANT_ID, SESSION_EXPIRY_DAYS } from 'app-constants';
import message from 'uikit/Message/Message';
import { isEmpty } from 'utils/validators';
import { stokenAtom } from 'containers/CompanyList/atoms/stoken-atom';
import querString from 'query-string';
import {
  GoogleLoginResponseType,
  LoginFormTypes,
  LoginResponseType,
} from './login-types';
import { GET_GOOGLE_LOGIN_URL_SELECTOR } from './login-actions';

export const getGoogleLoginUrlSelector = selector({
  key: GET_GOOGLE_LOGIN_URL_SELECTOR,
  get: async () => {
    const googleLoginUrlResponse: GoogleLoginResponseType = await fetchClient(
      'normal',
    ).get(getGoogleLoginUrlApi());
    const { url } = googleLoginUrlResponse.data;
    const [gAuth, gAuthQuery] = url.split('?');
    const parsedUrl = querString.parse(gAuthQuery);
    const redirect_uri = `${window.location.origin}/auth/google/callback`;
    const googleLoginUrl = `${gAuth}?${querString.stringify({
      ...parsedUrl,
      redirect_uri,
    })}`;
    return {
      ...googleLoginUrlResponse.data,
      url: googleLoginUrl,
    };
  },
});

export const loginCallback =
  ({ set }: CallbackInterface) =>
  async ({ email, password }: LoginFormTypes) => {
    const loginResponse: LoginResponseType = await fetchClient('normal').post(
      loginApi(),
      {
        session: {
          email,
          password,
        },
      },
    );
    const { token, tenant_id, role, status } = loginResponse.data;

    if (status === 'success' && !isEmpty(tenant_id)) {
      set(stokenAtom, token);
      setCookie(FIRM_TENANT_ID, tenant_id, SESSION_EXPIRY_DAYS);
      setSession({
        token,
        role: `${role}`,
        tenantId: tenant_id,
      });
    } else {
      set(stokenAtom, '');
      message.error('Wrong Credentials');
    }
    return {
      ...loginResponse.data,
      authenticated: !isEmpty(token),
      isRegistered: !isEmpty(tenant_id),
    };
  };
