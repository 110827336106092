import { fromJS } from 'immutable';
import {
  STORE_COMPLIANCES,
  REMOVE_COMPLIANCES,
  STORE_TASKS,
  GET_SUB_CATEGORIES,
  STORE_SUB_CATEGORIES,
  STORE_DATA_ROOM_DETAILS,
  STORE_DATA_ROOM_FILES,
  GET_ALL_NEW_TAXATION,
  STORE_ALL_NEW_TAXATION,
  STORE_CATEGORY_WISE_REPO,
  STORE_DATA_CATEGORY_ROOM_FILES,
  STORE_DATA_CATEGORY_ROOM_ALL_FILES,
  STORE_DATA_ROOM_FOLDERS,
  UPLOAD_REPO_SUCCESS,
  STORE_QUARTER_DATA_ROOM,
  STORE_DATA_CATEGORY_ROOM_PERSONAL_FILES,
  UPLOAD_STARTED,
  UPLOAD_COMPLETED,
  UPLOAD_FAILED,
} from './constants';

const initialState = fromJS({
  categories: [],
  newTaxation: [],
  parent_category_name: null,
  pageLoader: true,
  tableLoader: false,
  folders: [],
  files: [],
  filters: {
    status: [],
  },
  catFiles: [],
  catFolders: [],
  uploadData: {},
  fileUploadInProgress: false,
});

export default function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUB_CATEGORIES:
      return state.merge({ pageLoader: true });
    case STORE_SUB_CATEGORIES:
      return state.merge({
        categories: action.data,
        pageLoader: false,
        parent_category_name: action.parent_category_name,
      });
    case STORE_COMPLIANCES:
      return state.merge({ compliances: action.data, tableLoader: false });
    case REMOVE_COMPLIANCES:
      return state.merge({ compliances: null, tableLoader: true });
    case STORE_TASKS:
      return state.merge({ tasks: action.data });
    case STORE_DATA_ROOM_DETAILS:
      return state.merge({ folders: action.data });
    case STORE_DATA_ROOM_FILES:
      return state.merge({ files: action.data });
    case STORE_DATA_CATEGORY_ROOM_FILES:
      return state.merge({ catFiles: action.data });
    case STORE_DATA_CATEGORY_ROOM_ALL_FILES:
      return state.merge({ catAllFiles: action.data });
    case STORE_DATA_ROOM_FOLDERS:
      return state.merge({ catAllFolders: action.data, pageLoader: false });
    case STORE_CATEGORY_WISE_REPO:
      return state.merge({ catFolders: action.data });
    case GET_ALL_NEW_TAXATION:
      return state.merge({ pageLoader: false });
    case STORE_ALL_NEW_TAXATION:
      return state.merge({ newTaxation: action.data, pageLoader: false });
    case UPLOAD_REPO_SUCCESS:
      return state.merge({ uploadData: action.data });
    case STORE_DATA_CATEGORY_ROOM_PERSONAL_FILES:
      return state.merge({ personalFileData: action.data });
    case UPLOAD_STARTED:
      return state.merge({ fileUploadInProgress: true });
    case UPLOAD_COMPLETED:
      return state.merge({ fileUploadInProgress: false });
    case UPLOAD_FAILED:
      return state.merge({ fileUploadInProgress: false });
    default:
      return state;
  }
}
