import React from 'react';
import classNames from 'classnames/bind';
import SvgGoogleLogo from 'icons/SvgGoogleLogo';
import Button from 'uikit/Button/Button';
import Flex from 'uikit/Flex/Flex';
import { useRecoilValue } from 'recoil';
import * as styles from './google-login.module.css';
import { getGoogleLoginUrlSelector } from './atoms/login-atoms';

const cx = classNames.bind(styles);
export function GoogleLogin() {
  const { url } = useRecoilValue(getGoogleLoginUrlSelector);
  return (
    <Flex flex="1" middle center>
      <a href={url}>
        <Button>
          <SvgGoogleLogo className={cx('google-logo')} size="medium" />
          Sign in with Google
        </Button>
      </a>
    </Flex>
  );
}
