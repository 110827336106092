import { ADMIN_PERMISSION } from './authorize-constant';
import { IsPermissionPresentTypes } from './authorize-types';

export const isPermissionPresent = ({
  type,
  permissions,
}: IsPermissionPresentTypes) => {
  const isPermissionFound = permissions.find((name) => name === type);
  const isAdmin = permissions.find((name) => name === ADMIN_PERMISSION);
  return typeof isPermissionFound === 'string' || typeof isAdmin === 'string';
};
