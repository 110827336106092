import { fromJS } from 'immutable';
import {
  USER_REGISTERED,
  REGISTER_USER,
  REQUEST_ERROR,
  STORE_EMUNS,
  GET_COMPANIES_DETAILS,
  STORE_COMPANY_DETAILS,
} from './constants';

const registrationInitialState = fromJS({
  registerBtnLoader: false,
  enums: {},
  companyDetails: {},
  companyDetailsLoader: false,
  userRegistered: false,
});

const companyDetailsReducer = (
  state = registrationInitialState,
  action = {},
) => {
  switch (action.type) {
    case REGISTER_USER:
      return state.merge({ registerBtnLoader: true, userRegistered: false });
    case USER_REGISTERED:
      return state.merge({ registerBtnLoader: false, userRegistered: true });
    case REQUEST_ERROR:
      return state.merge({ registerBtnLoader: false, userRegistered: false });
    case STORE_EMUNS:
      return state.merge({ enums: action.enums, userRegistered: false });
    case GET_COMPANIES_DETAILS:
      return state.merge({ companyDetailsLoader: true, userRegistered: false });
    case STORE_COMPANY_DETAILS:
      return state.merge({
        companyDetails: action.companyDetails,
        companyDetailsLoader: false,
        userRegistered: false,
      });
    default:
      return state;
  }
};

export default companyDetailsReducer;
