import { SUPER_TOKEN } from 'app-constants';
import { RootState } from 'configureStore';
import MyProfileProvider from 'containers/App/my-profile-provider';
import { stokenAtom } from 'containers/CompanyList/atoms/stoken-atom';
import { myProfileSelector } from 'containers/account-settings/Atom/account-settings-atom';
import { profileDataTemp } from 'containers/account-settings/Atom/account-settings-constant';
import { MyProfileResponse } from 'containers/account-settings/Atom/account-settings.types';
import React, { useEffect, type ReactNode, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { getCookie } from 'utils/helper';

type Props = {
  authenticated?: boolean;
  stoken?: string;
  children: ReactNode;
  path: string;
};
const getStoken = () => getCookie(SUPER_TOKEN);
function AuthorisedRoutes({
  children,
  authenticated,
  stoken,
  ...rest
}: Readonly<Props>) {
  const myProfileState = useRecoilValueLoadable(myProfileSelector);
  const setStoken = useSetRecoilState(stokenAtom);
  const [myProfile, setMyProfile] =
    useState<MyProfileResponse>(profileDataTemp);
  const cookieStoken = getStoken();
  const requiredStoken = cookieStoken || stoken;
  const isLoggedIn = authenticated || requiredStoken;
  useEffect(() => {
    if (isLoggedIn && requiredStoken) {
      setStoken(requiredStoken);
    }
  }, [isLoggedIn, requiredStoken, setStoken]);

  useEffect(() => {
    if (myProfileState.state === 'hasValue') {
      setMyProfile(myProfileState.contents);
    }
  }, [myProfileState]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn && myProfile ? (
          <MyProfileProvider myProfile={myProfile}>
            {children}
          </MyProfileProvider>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = (state: RootState) => {
  const auth = state.get('auth');
  return {
    authenticated: auth.get('authenticated'),
    stoken: auth.get('authToken'),
  };
};
export default connect(mapStateToProps)(AuthorisedRoutes);
