export const DASHBOARD_SUMMARY_ATOM = 'dashboard/SummaryData/atom';
export const DASHBOARD_SUMMARY_SELECTOR = 'dashboard/SummaryData/selector';
export const COMPANY_OVERVIEW_ATOM = 'dashboard/companyoverview/atom';
export const COMPANY_OVERVIEW_SELECTOR = 'dashboard/companyoverview/selector';
export const OPEN_TASKS_ATOM = 'dashboard/opentasks/atom';
export const OPEN_TASKS_SELECTOR = 'dashboard/opentasks/selector';
export const COMPANY_OVERVIEW_FILTER = 'dashboard/companyoverviewfilter/atom';
export const ALL_ADMIN_COMPANIES_ATOM = 'dashboard/allcompanies/admin/atom';
export const ALL_ADMIN_COMPANIES_SELECTOR =
  'dashboard/allcompanies/admin/selector';
export const REQUEST_COMPANY_ACCESS_ATOM = 'dashboard/companyaccess/atom';
export const REQUEST_COMPANY_ACCESS_SELECTOR =
  'dashboard/companyaccess/selector';
export const GET_ENUM_ATOM = 'dashboard/getEnumsAPIRegistration/atom';
export const GET_ENUM_SELECTOR = 'dashboard/getEnumsAPIRegistration/selector';
export const ALL_COMPANIES_ATOM = 'dashboard/allcompanies/atom';
export const ALL_COMPANIES_SELECTOR = 'dashboard/allcompanies/selector';
export const ADD_NEW_COMPANY_ATOM = 'dashboard/addnewcompany/atom';
export const ADD_NEW_COMPANY_SELECTOR = 'dashboard/addnewcompany/selector';
export const ADD_NEW_USER_ATOM = 'dashboard/addnewuser/atom';
export const ADD_NEW_USER_SELECTOR = 'dashboard/addnewuser/selector';
export const STOKEN_ATOM = 'dashboard/stoken/atom';
export const ALL_TASKS_ATOM = 'dashboard/alltasks/atom';
export const ALL_TASKS_SELECTOR = 'dashboard/alltasks/selector';
export const ALL_CATEGORIES_SELECTOR = 'dashboard/categoriestask/selector';
export const CHILD_CATEGORIES_ATOM = 'dashboard/categories/child/task/atom';
export const CHILD_CATEGORIES_SELECTOR =
  'dashboard/categories/child/task/selector';
export const GENERIC_TAGS_SELECTOR = 'task/generictags/selector';
export const SWITCH_COMPANY_ACTION = 'main/SWITCH_COMPANY';
export const MY_COMPANIES_DROPDOWN_SELECTOR =
  'dashboard/MY_COMPANIES_DROPDOWN_SELECTOR';
export const MY_COMPANIES_DROPDOWN_PAGINATION_ATOM =
  'dashboard/MY_COMPANIES_DROPDOWN_PAGINATION_ATOM';
export const COMPANY_NAME_ATOM = 'dashboard/COMPANY_NAME_ATOM';
export const FIRM_TENANT_ID = 'dashboard/FIRM_TENANT_ID';
export const COMPANY_OVERVIEW_REFRESH_ATOM = 'dashboard/COMPANY_OVERVIEW_REFRESH_ATOM'