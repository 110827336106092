import { CallbackInterface, atom, selector } from 'recoil';
import {
  branchupsertApi,
  companyupsertApi,
  editUserProfile,
  fetchMyProfileApi,
  getCompanyDetailsApi,
  updateMyProfileApi,
} from 'utils/api';
import { fetchClient } from 'utils/api-client';
import { errorMessage, successMessage } from 'utils/helpers';
import { stokenAtom } from 'containers/CompanyList/atoms/stoken-atom';
import Message from 'uikit/Message/Message';
import { switchCompanyAtom } from 'containers/CompanyList/atoms/switch-company-helper';
import { getToken } from 'utils/helper';
import { userManagementRefreshAtom } from 'containers/UserManagement/atoms/usersAndGroupsRefreshAtoms';
import { dispatch } from 'configureStore';
import {
  fetchBranchesAtom,
  refreshBranchAtom,
} from 'branches/atoms/branches-atoms';
import {
  COMPANY_PROFILE_SELECTOR,
  MY_PROFILE_SELECTOR,
  PROFILE_REFETCH,
  PERMISSIONS_ATOM,
  MYCOMPANY_SELECTOR,
  profileDataTemp,
  COMPANY_INFORMATION_REFETCH_ATOM,
} from './account-settings-constant';
import {
  EditUserResponseType,
  RegisterBranchUpdate,
  UserEditForm,
  SpecialPermissionsType,
  MyProfileResponseFormatData,
  RegisterCompanyupdateType,
  RegisterBranchUpdateResponseType,
  CompanyInfoResponseType,
} from './account-settings.types';
import { STORE_PROFILE_DETAILS } from '../constants';
import { companyConstant } from './account-settings-helper';

const handleFetchMyProfile = async (token: string) => {
  if (!token) {
    return profileDataTemp;
  }
  const response: MyProfileResponseFormatData = await fetchClient('normal', {
    headers: {
      stoken: token,
    },
  }).get(fetchMyProfileApi());
  const profileDetails = response.data.data;

  if (profileDetails) {
    dispatch({
      type: STORE_PROFILE_DETAILS,
      data: response.data.data.my_profile,
    });
  }

  return {
    ...profileDetails,
  };
};

export const profileRefetchAtom = atom({
  key: PROFILE_REFETCH,
  default: 0,
});

export const companyInformationRefetchAtom = atom({
  key: COMPANY_INFORMATION_REFETCH_ATOM,
  default: 0,
});

export const specialPermissionsAtom = atom<SpecialPermissionsType>({
  key: PERMISSIONS_ATOM,
  default: {
    users: [],
    permissions: [],
    enable: false,
  },
});

export const companyProfileSelector = selector({
  key: COMPANY_PROFILE_SELECTOR,
  get: async ({ get }) => {
    get(switchCompanyAtom);
    const token = getToken();
    get(profileRefetchAtom);
    return handleFetchMyProfile(token);
  },
});

export const myProfileSelector = selector({
  key: MY_PROFILE_SELECTOR,
  get: async ({ get }) => {
    const stoken = get(stokenAtom);
    get(profileRefetchAtom);
    get(userManagementRefreshAtom);
    return handleFetchMyProfile(stoken);
  },
});

const initalUpdateCompanyPayload = {
  user_update: {
    dob: '',
    email: '',
    first_name: '',
    last_name: '',
  },
  token: '',
};

export const updateCompanyProfilePayload = atom({
  key: 'updateCompanyProfilePayload',
  default: initalUpdateCompanyPayload,
});

export const updateCompanyProfileSelector = selector({
  key: 'updateCompanyProfileSelector',
  get: async ({ get }) => {
    const data = get(updateCompanyProfilePayload);
    if (!data.user_update.email) {
      return {
        response: '',
        status: '',
      };
    }
    const response = (await fetchClient('normal', {
      headers: {
        stoken: data.token,
      },
    }).put(updateMyProfileApi(), {
      user_update: {
        ...data.user_update,
        email: data.user_update.email.toLowerCase(),
      },
    })) as {
      data: {
        response: string;
        status: string;
      };
    };
    if (response.data.status === 'ok') {
      successMessage(response.data.response);
    } else {
      errorMessage(response.data.response);
    }
    return response;
  },
});

export const editProfileCallback =
  ({ set }: CallbackInterface) =>
  async ({ first_name, last_name, user_id, token }: UserEditForm) => {
    try {
      const response = (await fetchClient('normal', {
        headers: {
          stoken: token,
        },
      }).put(editUserProfile(), {
        user_update: {
          first_name,
          last_name,
        },
        user_id,
      })) as EditUserResponseType;
      if (response.data.status === 'ok') {
        successMessage(response.data.response);
      } else {
        errorMessage(response.data.response);
      }
      set(profileRefetchAtom, (prev) => prev + 1);
      return response.data;
    } catch (error) {
      const typedError = error as Error;
      Message.error(typedError.message);
      throw new Error(typedError.message);
    }
  };

export const registerBranchUpdate =
  ({ set }: CallbackInterface) =>
  async ({
    address1,
    address2,
    branch_type,
    city,
    country,
    id,
    active,
    name,
    phone,
    state,
    company_id,
    zipcode,
    tenant_id,
    email,
    countryCode,
    gst,
    is_hq,
    branch_info,
  }: RegisterBranchUpdate) => {
    try {
      const payload = {
        branches: [
          {
            address1,
            address2: address2 ?? '',
            branch_type,
            city,
            country,
            company_id,
            id,
            active,
            name,
            phone,
            state,
            zipcode,
            email,
            gst,
            is_hq,
            branch_info,
          },
        ],
        tenant_id,
        country_code: countryCode,
      };
      const response = await fetchClient(
        'normal',
      ).post<RegisterBranchUpdateResponseType>(branchupsertApi(), payload);
      Message.success('Branch successfully updated');
      set(companyInformationRefetchAtom, (prev) => prev + 1);
      set(refreshBranchAtom, (prev) => prev + 1);
      return response.data;
    } catch (error) {
      const typedError = error as Error;
      Message.error(typedError.message);
      throw new Error(typedError.message);
    }
  };

export const registerCompanyCallback =
  ({ set }: CallbackInterface) =>
  async ({
    name,
    place_of_roc,
    cin,
    esi,
    pan,
    id,
    tan,
    gst,
    pf,
    company_type,
    industry_vertical,
    company_domain,
    active_from,
    date_of_incorporation,
    directors,
    tenant_id,
    base_branch_id,
    status,
    firm_type,
  }: RegisterCompanyupdateType) => {
    try {
      const payload = {
        company: {
          name,
          place_of_roc,
          cin,
          esi,
          pan,
          id,
          tan,
          gst,
          pf,
          company_type,
          industry_vertical,
          company_domain,
          active_from,
          date_of_incorporation,
          directors,
          base_branch_id,
          status,
          firm_type,
        },
        tenant_id,
      };
      const response = await fetchClient('normal').post(
        companyupsertApi(),
        payload,
      );
      Message.success('company successfully updated');
      set(profileRefetchAtom, (prev) => prev + 1);
      return response.data;
    } catch (error) {
      const typedError = error as Error;
      Message.error(typedError.message);
      throw new Error(typedError.message);
    }
  };

export const myCompanyApiSelector = selector({
  key: MYCOMPANY_SELECTOR,
  get: async ({ get }) => {
    get(companyInformationRefetchAtom);
    get(profileRefetchAtom);
    get(switchCompanyAtom);
    const { tenantId } = get(fetchBranchesAtom);
    if (!tenantId)
      return {
        ...companyConstant,
        country_code: 'IN',
        base_branch_id: '',
      };
    const response: CompanyInfoResponseType = await fetchClient('normal').get(
      getCompanyDetailsApi(tenantId),
    );
    return response.data.data.company;
  },
});
