export const STORE_COMPANY_LIST = 'STORE_COMPANY_LIST';

export const GET_COMPANY_LIST = 'GET_COMPANY_LIST';

export const GET_ALL_COMPANY_LIST = 'GET_ALL_COMPANY_LIST';

export const COMPANY_SWITCHED = 'COMPANY_SWITCHED';

export const STORE_EMUNS = 'STORE_EMUNS';
export const GET_ENUMS = 'GET_ENUMS';

export const START_UPDATE_USER = 'START_UPDATE_USER';

export const END_UPDATE_USER = 'END_UPDATE_USER';

export const COMPANY = 'Company';
export const ASSIGNEE = 'Assignee';
export const STATUS = 'Status';
