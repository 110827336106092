export const SUB_CATEGORY_ADDED = 'SUB_CATEGORY_ADDED';

export const STORE_ADMIN_CATEGORIES = 'STORE_ADMIN_CATEGORIES';

export const GET_CATEGORY_TREE = 'GET_CATEGORY_TREE';

export const STORE_FLATTENED_COMPLIANCES = 'STORE_FLATTENED_COMPLIANCES';

export const GET_GENERIC_TASKS = 'GET_GENERIC_TASKS';

export const STORE_GENERIC_TASKS = 'STORE_GENERIC_TASKS';

export const CATEGORY_UPDATED = 'CATEGORY_UPDATED';

export const RESET_PROP_CHANGE = 'RESET_PROP_CHANGE';

export const STORE_CATEGORY_TREE = 'STORE_CATEGORY_TREE';

export const STORE_ALL_EVENT = 'STORE_ALL_EVENT';

export const STORE_ALL_EVENT_DEPENDENCY = 'STORE_ALL_EVENT_DEPENDENCY';

export const STORE_ALL_TAGS = 'STORE_ALL_TAGS';
export const STORE_EVENT_MASTER = 'STORE_EVENT_MASTER'
