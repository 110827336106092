import { createRoot } from 'react-dom/client';
import React, { StrictMode, Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { RecoilURLSyncJSON } from 'recoil-sync';
import { Provider } from 'react-redux';
import 'sanitize.css/sanitize.css';
import 'global.css';
import MainApp from 'main-app';
import { BrowserRouter as Router } from 'react-router-dom';
import message from 'uikit/Message/Message';
import TimeTravelObserver from './uikit/TimeTravelObserver/time-travel-observer';
import { store } from './configureStore';
import ThemeProvider from './uikit/ThemeProvider/ThemeProvider';

const MOUNT_NODE =
  document.getElementById('app') || document.createElement('div');
const root = createRoot(MOUNT_NODE);

root.render(
  <StrictMode>
    <Provider store={store}>
      <RecoilRoot>
        <RecoilURLSyncJSON location={{ part: 'queryParams' }}>
          <ThemeProvider>
            <Suspense fallback={<p>loading...</p>}>
              <TimeTravelObserver />
              <Router>
                <MainApp />
              </Router>
            </Suspense>
          </ThemeProvider>
        </RecoilURLSyncJSON>
      </RecoilRoot>
    </Provider>
  </StrictMode>,
);

const isLocalhost =
  window.location.hostname === 'localhost' ||
  window.location.hostname === '[::1]' ||
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
  );

if ('serviceWorker' in navigator && !isLocalhost) {
  const basePath = window.origin;
  const swUrl = `${basePath}/sw.js`;
  navigator.serviceWorker.register(swUrl).catch((error) => {
    message.error(error.message);
  });
}
