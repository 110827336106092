import { atom } from 'recoil';
import { REFRESH_GROUPS_LIST } from 'containers/Groups/Atom/groupsConstant';
import { USER_MANAGEMENT_REFRESH } from './userManagementKeys';

export const userManagementRefreshAtom = atom({
  key: USER_MANAGEMENT_REFRESH,
  default: 0,
});

export const refreshGroupsListAtom = atom({
  key: REFRESH_GROUPS_LIST,
  default: 0,
});
