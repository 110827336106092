/* eslint-disable react/no-array-index-key */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import {
  useGotoRecoilSnapshot,
  useRecoilCallback,
  useRecoilSnapshot,
  useRecoilValue,
} from 'recoil';
import type { Snapshot } from 'recoil';
import classNames from 'classnames/bind';
import { configs } from '../../server-url';

import * as styles from './TimeTravelObserver.module.css';
import { showDebuggerAtom } from './store/timetravelleratom';
import { ENV_DEVELOPMENT } from '../../app-constants';

const cx = classNames.bind(styles);

function TimeTravelObserver() {
  const [snapshots, setSnapshots] = useState<Snapshot[]>([]);
  const showDebugger = useRecoilValue(showDebuggerAtom);
  const snapshot = useRecoilSnapshot();
  const release = snapshot.retain();
  useEffect(() => {
    const hasNewSnapshot = snapshots.every(
      (s) => s.getID() !== snapshot.getID(),
    );
    if (hasNewSnapshot) {
      setSnapshots([...snapshots, snapshot]);
    }
  }, [snapshot, snapshots]);

  const handleLogDetails = useRecoilCallback(
    ({ snapshot: snap }) =>
      async () => {
        try {
          console.debug('Atom values:');
          for (const node of snap.getNodes_UNSTABLE()) {
            const value = await snap.getPromise(node);
            console.debug(node.key, value);
          }
        } catch (error) {
          const typedError = error as Error;
          console.log(typedError.toString());
        }
        release();
      },
    [release],
  );

  const gotoSnapshot = useGotoRecoilSnapshot();
  if (configs.env !== ENV_DEVELOPMENT || !showDebugger) {
    return null;
  }
  return (
    <div className={cx('title', { 'title--open': showDebugger })}>
      <ol className={cx('container')}>
        {snapshots.map((snapshotlist, i) => (
          <li key={i} className={cx('snapshot')}>
            Snapshot
            <button
              type="button"
              onClick={handleLogDetails}
              className={cx('button')}
            >
              Debug
            </button>
            <button
              type="button"
              className={cx('button')}
              onClick={() => {
                gotoSnapshot(snapshotlist);
                handleLogDetails();
              }}
            >
              Restore
            </button>
          </li>
        ))}
      </ol>
    </div>
  );
}

export default TimeTravelObserver;
