import { REQUIRED_FIELD, isValidEmail } from 'utils/validators';

const panRuleValidate = (pan: string) => {
  const panRule = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
  if (!panRule.test(pan)) {
    return 'Invalid PAN';
  }
  return true;
};

const emailRuleValidate = (email: string) => {
  if (!isValidEmail(email)) {
    return 'Enter valid email';
  }
  return true;
};

export const validatePhone = (phone: string): string | boolean => {
  const indianPhoneRegex = /^[6789]\d{9}$/;
  if (!indianPhoneRegex.test(phone)) {
    return 'Not a valid phone number';
  }
  return true;
};

export const INDIVIDUAL_PHONE_RULES = {
  ...REQUIRED_FIELD,
  validate: validatePhone,
};

export const PAN_RULES = {
  ...REQUIRED_FIELD,
  validate: panRuleValidate,
};

export const EMAIL_RULES = {
  ...REQUIRED_FIELD,
  validate: emailRuleValidate,
};
