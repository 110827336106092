import { useEffect, useState } from 'react';
import { Snapshot, useGotoRecoilSnapshot, useRecoilSnapshot } from 'recoil';

export const useLogOutHook = () => {
  const [snapshots, setSnapshots] = useState<Snapshot[]>([]);

  const snapshot = useRecoilSnapshot();
  const release = snapshot.retain();
  useEffect(() => {
    const hasNewSnapshot = snapshots.every(
      (s) => s.getID() !== snapshot.getID(),
    );
    if (hasNewSnapshot) {
      setSnapshots([...snapshots, snapshot]);
    }
  }, [snapshot]);

  const gotoSnapshot = useGotoRecoilSnapshot();

  const clearAllAtom = () => {
    gotoSnapshot(snapshots[0]);
    release();
  };

  return {
    clearAllAtom,
  };
};
