import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import * as styles from './LinkWrapper.module.css';

const cx = classNames.bind(styles);
type Props = {
  to: string;
  children: ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  color?: 'white';
};

function LinkWrapper({ to, className, children, onClick, color }: Props) {
  return (
    <Link
      to={to}
      className={cx('link', className, {
        [`link-${color}`]: color,
      })}
      onClick={onClick}
    >
      <>{children}</>
    </Link>
  );
}

LinkWrapper.defaultProps = {
  className: undefined,
  onClick: undefined,
};
export default LinkWrapper;
