import { fromJS } from 'immutable';
import { USER_CREDENTIAL } from './constants';

const userCredential = fromJS({
  userCredentials: {},
});

export default function userCredentialReducer(state = userCredential, action) {
  switch (action.type) {
    case USER_CREDENTIAL:
      console.log('USER_CREDENTIAL', action);
      return state.merge({ userCredentials: action.userCredentials });
    default:
      return state;
  }
}
