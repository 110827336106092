import React, { useEffect } from 'react';
import type { ReactNode } from 'react';
import { generateCssColorVars } from 'colors/color-helpers';

type Props = {
  children: ReactNode;
};
function ThemeProvider({ children }: Props) {
  useEffect(() => {
    generateCssColorVars();
  }, []);
  return <>{children}</>;
}

export default ThemeProvider;
