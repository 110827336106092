import {
  useLocation as useLoc,
  useHistory as useHis,
  useParams as useParam,
  useRouteMatch as useRoute,
} from 'react-router-dom';

export const useLocation = useLoc;
export const useHistory = useHis;
export const useParams = useParam;
export const useRouteMatch = useRoute;
