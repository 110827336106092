import React from 'react';
import classNames from 'classnames/bind';
import Flex from 'uikit/Flex/Flex';
import VaultSaveAnimation from 'uikit/vault-save-animation/vault-save-animation';
import * as styles from './vault-save-loader.module.css';

const cx = classNames.bind(styles);

function VaultSaveContainerLoader() {
  return (
    <Flex center middle className={cx('loader-container')}>
      <div className={cx('loader')}>
        <VaultSaveAnimation />
      </div>
    </Flex>
  );
}

export default VaultSaveContainerLoader;
