import axios from 'axios';
import { stringify } from 'query-string';
import { TOKEN } from '../app-constants';
import { getCookie, removeUserSession } from './helper';

type InstanceType = 'upload' | 'normal';
type OptionsType = {
  headers?: { [key: string]: string };
};
const CONTENT_TYPE = 'Content-Type';
type HeaderType = {
  [CONTENT_TYPE]?: string;
};

type DefaultOptionsType = {
  headers: HeaderType;
  validateStatus: (stat: number) => boolean;
  paramsSerializer: (param: any) => string;
  Accept?: string;
  [CONTENT_TYPE]?: string;
};

type ValidatorErrorType = {
  value: string;
  location: string;
  message: string;
  param: string;
};

const handlePlatformApiError = (error: any) => {
  if (error?.response?.data) {
    if (
      typeof error.response.data.error === 'object' &&
      !Array.isArray(error.response.data.error) &&
      error.response.data.error.message
    ) {
      return new Error(error.response.data.error.message);
    }

    if (Array.isArray(error.response.data.error)) {
      let errorMessage = error.response.data.error.reduce(
        (message: string, err: ValidatorErrorType) =>
          `${message}, The field ${err.param} sent in ${err.location} is ${err.message}`,
        '',
      );
      errorMessage = errorMessage.replace(', ', '');
      return new Error(errorMessage);
    }
  }
  return new Error(error);
};

export const fetchClient = (
  instanceType: InstanceType,
  options: OptionsType = {},
) => {
  const defaultOptions: DefaultOptionsType = {
    headers: {},
    validateStatus: (status: number) => status < 400,
    paramsSerializer: (params: any) => stringify(params),
  };
  if (instanceType !== 'upload') {
    defaultOptions.headers[CONTENT_TYPE] = 'application/json; charset=utf-8';
    defaultOptions.Accept = 'application/json';
  } else if (instanceType === 'upload') {
    defaultOptions[CONTENT_TYPE] =
      'multipart/form-data; boundary=----WebKitFormBoundaryLnifHufJghtAmJGM';
  }

  const instance = axios.create({
    ...defaultOptions,
    headers: options.headers
      ? {
          ...defaultOptions.headers,
          ...options.headers,
        }
      : {
          ...defaultOptions.headers,
        },
  });

  instance.interceptors.request.use((config) => {
    const token = getCookie(TOKEN);
    const newConfig = { ...config };
    newConfig.withCredentials = config.url?.includes('/api/v1') ? true : false;
    newConfig.headers.stoken = options.headers?.stoken
      ? options.headers?.stoken
      : token;
    return newConfig;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorMessage = handlePlatformApiError(error);
      if (error.response && error.response.status === 401) {
        removeUserSession();
        const requiredError = new Error(`${errorMessage.message}, Kindly relogin to continue.`);
        return Promise.reject(requiredError);
      }

      return Promise.reject(errorMessage);
    },
  );

  return instance;
};
