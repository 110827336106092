export const LIST_USER_MANAGEMENT_SELECTOR =
  'user/management/LIST_USER_MANAGEMENT_SELECTOR';
export const USER_MANAGEMENT_REFRESH =
  'user/management/USER_MANAGEMENT_REFRESH';
export const USER_MANAGEMENT_PAGINATION =
  'user/management/USER_MANAGEMENT_PAGINATION';
export const ROLES_DROPDOWN_SELECTOR =
  'user/management/ROLES_DROPDOWN_SELECTOR';
export const ROLES_DROPDOWN_ATOM = 'user/management/ROLES_DROPDOWN_ATOM';
export const GROUPS_DROPDOWN_SELECTOR =
  'user/management/GROUPS_DROPDOWN_SELECTOR';
export const GROUPS_DROPDOWN_ATOM = 'user/management/GROUPS_DROPDOWN_ATOM';
export const ASSIGNED_COMPANIES_DROPDOWN_SELECTOR =
  'user/management/ASSIGNED_COMPANIES_DROPDOWN_SELECTOR';
export const ASSIGNED_COMPANIES_DROPDOWN_ATOM =
  'user/management/ASSIGNED_COMPANIES_DROPDOWN_ATOM';
