import React, { ReactNode } from 'react';
import { Paragraph } from 'uikit/Typography/Typography';
import Flex from 'uikit/Flex/Flex';
import LinkWrapper from 'uikit/linkwrapper/linkwrapper';

type LoginLabelDefaultProps = {
  showForgotPassword: boolean;
};

const defaultProps: LoginLabelDefaultProps = {
  showForgotPassword: true,
};

type LoginLabelProps = {
  children: ReactNode;
} & LoginLabelDefaultProps;

export function LoginLabel({
  children,
  showForgotPassword,
}: Readonly<LoginLabelProps>) {
  return (
    <Flex row between baseline>
      <Paragraph>{children}</Paragraph>
      {showForgotPassword && (
        <Paragraph>
          <LinkWrapper to="/forgot-password">Forgot password?</LinkWrapper>
        </Paragraph>
      )}
    </Flex>
  );
}

LoginLabel.defaultProps = defaultProps;
