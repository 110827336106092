import React from 'react';
import { Spin } from 'antd';
import Flex from '../Flex/Flex';

type SpinnerLoaderProps = {
  testId?: string;
};

const defaultProps: SpinnerLoaderProps = {
  testId: 'spinner-loader',
};

function SpinnerLoader({ testId }: SpinnerLoaderProps) {
  return (
    <Flex center middle>
      <Spin data-testid={testId} />
    </Flex>
  );
}

SpinnerLoader.defaultProps = defaultProps;
export default SpinnerLoader;
