import React from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from 'antd';
import * as styles from './SkeletonLoader.module.css';

const cx = classNames.bind(styles);
type Props = {
  className?: string;
  testId?: string;
};

function SkeletonLoader({ className, testId }: Props) {
  return <Skeleton active className={cx(className)} prefixCls={testId} />;
}

export default SkeletonLoader;
