import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router/immutable';
import createReducer from './reducers';
import history from './utils/history';

export const createStore = () => {
  const reducers = createReducer();
  const middlewares = [routerMiddleware(history)];
  return configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middlewares),
  });
};

export const store = createStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const { dispatch } = store;
