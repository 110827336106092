import { atom, selector, CallbackInterface } from 'recoil';
import {
  updateBookmarkApi,
  deleteBookmarkApi,
  fetchBookmarkListApi,
} from 'utils/api';
import Message from 'uikit/Message/Message';
import { setCookie, showToast } from 'utils/helper';
import { stokenAtom } from 'containers/CompanyList/atoms/stoken-atom';
import { fetchClient } from '../../../utils/api-client';
import {
  BookmarkMyCompanyListResponse,
  CreateBookmarkResponseType,
  DeleteBookmarksResponseType,
  MyCompanyNameTypes,
} from './bookmark.types';
import { BOOKMARK_LIST_ATOM, BOOKMARK_LIST_SELECTOR } from './bookmarkAction';
import { COMPANY_LIST, SESSION_EXPIRY_DAYS } from '../../../app-constants';

export const bookmarkListAtom = atom({
  key: BOOKMARK_LIST_ATOM,
  default: 0,
});

export const bookmarkListSelector = selector({
  key: BOOKMARK_LIST_SELECTOR,
  get: async ({ get }) => {
    get(bookmarkListAtom);
    const stoken = get(stokenAtom);
    if (!stoken) {
      return {
        bookmarkList: [],
        bookmarkDictionary: {},
        companyListSideMenu: [],
      };
    }
    const result = (await fetchClient('normal', {
      headers: {
        stoken,
      },
    }).get(fetchBookmarkListApi())) as BookmarkMyCompanyListResponse;
    const bookmarkListResponse = result.data.response || [];

    const initial: { [key: string]: string } = {};
    const bookmarkListReduce = bookmarkListResponse.reduce((acc, item) => {
      const { company_id, tenant_id } = item;
      acc[tenant_id] = company_id;
      return acc;
    }, initial);

    const companyList = bookmarkListResponse.map((bookmark) => {
      return {
        companyName: bookmark.company_name,
        tenantId: bookmark.tenant_id,
      };
    });
    setCookie(COMPANY_LIST, JSON.stringify(companyList), SESSION_EXPIRY_DAYS);
    return {
      bookmarkList: bookmarkListResponse,
      bookmarkDictionary: bookmarkListReduce,
      companyListSideMenu: companyList,
    };
  },
});

export const updateBookmarkListCallBack =
  ({ set }: CallbackInterface) =>
  async ({ company_id, stoken }: MyCompanyNameTypes) => {
    try {
      const result = (await fetchClient('normal', {
        headers: {
          stoken,
        },
      }).post(updateBookmarkApi(), {
        company_id,
      })) as CreateBookmarkResponseType;
      set(bookmarkListAtom, (prev) => prev + 1);
      showToast(result.data.status, result.data.msg);
      return result.data;
    } catch (error) {
      const typedError = error as Error;
      Message.error(typedError.message);
      throw new Error(typedError.message);
    }
  };

export const deleteBookmarkListCallBack =
  ({ set }: CallbackInterface) =>
  async ({ company_id, stoken }: MyCompanyNameTypes) => {
    try {
      const result = (await fetchClient('normal', {
        headers: {
          stoken,
        },
      }).delete(deleteBookmarkApi(), {
        data: { company_id },
      })) as DeleteBookmarksResponseType;
      set(bookmarkListAtom, (prev) => prev + 1);
      showToast(result.data.status, result.data.response);
      return result;
    } catch (error) {
      const typedError = error as Error;
      Message.error(typedError.message);
      throw new Error(typedError.message);
    }
  };
