import { fromJS } from 'immutable';
import {
  STORE_FLATTENED_COMPLIANCES,
  STORE_GENERIC_TASKS,
  GET_GENERIC_TASKS,
  RESET_PROP_CHANGE,
  STORE_CATEGORY_TREE,
  STORE_ALL_EVENT,
  STORE_ALL_EVENT_DEPENDENCY,
  STORE_ALL_TAGS,
} from './constants';

const initialState = fromJS({
  Edata: {},
  tasksTableLoader: true,
  genericTasks: {},
  isCatTreeChanged: false,
  isFlatCompliancesChanged: false,
  isEventChanged: false,
});

export default function adminHomeReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_CATEGORY_TREE:
      return state.merge({ categoryTree: action.data, isCatTreeChanged: true });
    case STORE_FLATTENED_COMPLIANCES:
      return state.merge({
        flattenedCompliances: action.data,
        isFlatCompliancesChanged: true,
      });
    case GET_GENERIC_TASKS:
      return state.merge({ tasksTableLoader: true });
    case STORE_GENERIC_TASKS: {
      const temp = state.get('genericTasks').toJS();
      temp[action.complianceId] = action.data;
      return state.merge({ genericTasks: temp, tasksTableLoader: false });
    }
    case RESET_PROP_CHANGE:
      return state.merge({
        isCatTreeChanged: false,
        isFlatCompliancesChanged: false,
      });
    case STORE_ALL_EVENT:
      return state.merge({ Edata: action.data });
    case STORE_ALL_EVENT_DEPENDENCY:
      return state.merge({
        eventsDataDependency: action.data,
        isEventChanged: true,
      });
    case STORE_ALL_TAGS:
      return state.merge({ allTags: action.data });
    default:
      return state;
  }
}
