import { atom, selector } from 'recoil';
import { fetchClient } from 'utils/api-client';
import { getBranchesListApi } from 'utils/api';
import { getFormatedBranches } from 'containers/CompanyList/TaskView/atoms/task-atom-helper';
import { REGISTER_BRANCH_REFRESH } from 'containers/account-settings/Atom/account-settings-constant';
import { BranchesResponseType } from './branches-types';
import {
  FETCH_BRANCHES_ATOMS,
  FETCH_BRANCHES_SELECTORS,
} from './branches-constants';

export const fetchBranchesAtom = atom({
  key: FETCH_BRANCHES_ATOMS,
  default: {
    tenantId: '',
  },
});

export const refreshBranchAtom = atom({
  key: REGISTER_BRANCH_REFRESH,
  default: 0,
});

export const fetchBranchesSelector = selector({
  key: FETCH_BRANCHES_SELECTORS,
  get: async ({ get }) => {
    const { tenantId } = get(fetchBranchesAtom);
    get(refreshBranchAtom);
    if (!tenantId)
      return {
        branches: [],
        formatedBranches: [],
        success: false,
        branchOptions: [],
      };

    const branchesResponse: BranchesResponseType = await fetchClient(
      'normal',
    ).get(getBranchesListApi(tenantId));
    const { data = [], success } = branchesResponse.data;
    const branchOptions = data.map((branch) => {
      const zipCode = branch.zipcode ?? '';
      const branchLabel = `${branch.branch_name} ${zipCode}`;
      return {
        label: branchLabel,
        value: branch.branch_id,
      };
    });

    const formatedBranches = getFormatedBranches(data);
    return {
      branches: data,
      formatedBranches,
      success,
      branchOptions,
    };
  },
});
