import React from 'react';
import classNames from 'classnames/bind';
import { Paragraph, Title } from 'uikit/Typography/Typography';
import Flex from 'uikit/Flex/Flex';
import Button from 'uikit/Button/Button';
import SvgRefresh from 'icons/SvgRefresh';
import * as styles from './ErrorText.module.css';

const cx = classNames.bind(styles);

type Props = {
  errorMessage: string;
  handleClear: React.MouseEventHandler<HTMLElement>;
};
function ErrorText({ errorMessage, handleClear }: Props) {
  return (
    <div className={cx('error-text')}>
      <Flex row center>
        <Title level={4} align="center">
          Sorry, something went wrong.
        </Title>
        <Button onClick={handleClear} shape="round" className={cx('button')}>
          <SvgRefresh />
        </Button>
      </Flex>
      <Paragraph align="center">
        We&apos;re working on it and we&apos;ll get it fixed as soon as we can
      </Paragraph>
      <Flex middle>
        <Paragraph align="center" type="danger" className={cx('error-message')}>
          {errorMessage}
        </Paragraph>
      </Flex>
    </div>
  );
}

export default ErrorText;
