import React from 'react';
import { VaultSaveAnimation as VaultSaveAnimationWidget } from '@turbocomplynpm/vault-save-animation';

type Props = {
  onLoopComplete?: () => void;
};
function VaultSaveAnimation({ onLoopComplete }: Props) {
  return <VaultSaveAnimationWidget onLoopComplete={onLoopComplete} />;
}

export default VaultSaveAnimation;
