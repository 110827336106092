import { atom } from 'recoil';

export type SidePanelCompanyList = {
  companyName: string;
  tenantId: string;
};

export const sidePanelDefaultOpenkeysAtom = atom<string>({
  key: 'sidePanelDefaultOpenkeys',
  default: '',
});

export const sidePanelCompanyListAtom = atom<SidePanelCompanyList[]>({
  key: 'sidePanelCompanyList',
  default: [],
});
