import { fromJS } from 'immutable';

const signUpInitialState = fromJS({
  registerBtnLoader: false,
  verifyBtnLoader: false,
  registerBtnLoader: false,
  isLoading: false,
  signUpData: {},
  error: {},
  verifyData: {},
  verifyDataError: {},
  enums: {},
  registerError: {},
  registerData: {},
  info: {},
});

export function signUpReducer(state = signUpInitialState, action) {
  switch (action.type) {
    case 'SIGN_UP_REQUESTED':
      return state.merge({ registerBtnLoader: true });
    case 'SIGN_UP_SUCCESS':
      return state.merge({
        registerBtnLoader: false,
        signUpData: action.payload,
      });
    case 'SIGN_UP_ERROR':
      return state.merge({ registerBtnLoader: false, error: action.payload });
    case 'USER_SIGNUP_INFO':
      return state.merge({ info: action.payload });
    case 'STORE_EMUNS':
      return state.merge({ enums: action.enums });
    default:
      return state;
  }
}

export function verifyUserReducer(state = signUpInitialState, action) {
  switch (action.type) {
    case 'VERIFY_USER_REQUESTED':
      return state.merge({ verifyBtnLoader: true });
    case 'VERIFY_USER_SUCCESS':
      return state.merge({
        verifyBtnLoader: false,
        verifyData: action.payload,
      });
    case 'VERIFY_USER_ERROR':
      return state.merge({
        verifyBtnLoader: false,
        verifyDataError: action.payload,
      });
    case 'REGISTER_AUDITOR_REQUESTED':
      return state.merge({ registerBtnLoader: true });
    case 'REGISTER_AUDITOR_SUCCESS':
      return state.merge({
        registerBtnLoader: false,
        registerData: action.payload,
      });
    case 'REGISTER_AUDITOR_ERROR':
      return state.merge({
        registerBtnLoader: false,
        registerError: action.payload,
      });
    default:
      return state;
  }
}
