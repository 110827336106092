import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { trackErrorEvent } from 'utils/analytics';
import Clickable from 'uikit/Clickable/Clickable';
import { Paragraph } from 'uikit/Typography/Typography';
import Flex from 'uikit/Flex/Flex';
import type { ErrorBoundaryProps, ErrorState } from './ErrorBoundary.types';
import ErrorText from './ErrorText';
import * as styles from './ErrorBoundary.module.css';

const cx = classNames.bind(styles);

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: '',
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps: Readonly<ErrorBoundaryProps>): void {
    const { resetKeys } = this.props;
    const { hasError } = this.state;
    if (
      resetKeys &&
      prevProps.resetKeys &&
      prevProps.resetKeys.toString() !== resetKeys.toString() &&
      hasError
    ) {
      this.resetError();
    }
  }

  componentDidCatch(error: Error) {
    const errorMessage = error.message ?? 'Something went wrong.';
    const { history } = this.props;
    if (errorMessage.includes('401') && typeof history !== 'undefined') {
      this.resetError();
      history.push('/login');
    } else {
      this.setState({
        error: errorMessage,
      });
      trackErrorEvent(errorMessage);
    }
  }

  resetError = () => {
    this.setState({
      hasError: false,
      error: '',
    });
  };

  handleClear: React.MouseEventHandler<HTMLElement> = (event) => {
    const { reset } = this.props;
    if (typeof reset === 'function') {
      reset(event);
    }
    this.resetError();
  };

  handleReloadPage = () => {
    window.location.reload();
  };

  render() {
    const { hasError, error } = this.state;
    const { children, reset } = this.props;

    if (hasError) {
      return (
        <div className={cx('error')}>
          <ErrorText errorMessage={error} handleClear={this.handleClear} />
          {typeof reset === 'undefined' && (
            <Flex row>
              <Paragraph>Try</Paragraph>
              <Clickable
                onClick={this.handleReloadPage}
                className={cx('click')}
              >
                <Paragraph link>refreshing</Paragraph>
              </Clickable>
              <Paragraph>the page</Paragraph>
            </Flex>
          )}
        </div>
      );
    }
    return <>{children}</>;
  }
}

export default ErrorBoundary
