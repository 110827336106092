/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-restricted-properties */
import moment from 'moment';
import React from 'react';
import { message, Tag } from 'antd';
import queryString from 'query-string';
import { getToken } from './helper';

const ADVANCE_100 = '100% Advance';
const DELIVERY_100 = '100% on Delivery';
export function convertUTCToLocalTime(UTCTime) {
  return moment(UTCTime).format('DD/MM/YYYY');
}

export function getUTCInMonthName(UTCTime) {
  return moment(UTCTime).format('DD MMM, YYYY');
}

export function getUTCInMonthNameWithTime(UTCTime) {
  return moment(UTCTime).format('DD MMM, YYYY, h:mm a');
}

export function successMessage(msg) {
  message.success(msg);
}

export function errorMessage(msg) {
  message.error(msg);
}

export function warningMessage(msg) {
  message.warn(msg);
}

export function roundToTwoDecimals(value, digits) {
  if (digits && digits > 0)
    return Math.round(value * 10 ** digits) / 10 ** digits;
  return Math.round(value);
}

export function getSeverityColor(severityCode) {
  let severityField = null;
  if (severityCode === 10) {
    severityField = <Tag color="red">high</Tag>;
  }
  if (severityCode === 5) {
    severityField = <Tag color="orange">medium</Tag>;
  }
  if (severityCode === 1) {
    severityField = <Tag color="green">low</Tag>;
  }
  return severityField;
}

export function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().startOf('day');
}

export function disabledHours() {
  const presentHour = moment().hour();
  const hours = [];
  let i = 0;
  while (i < presentHour) {
    hours.push(i);
    i += 1;
  }
  return hours;
}

export const parse = (string) => queryString.parse(string);

export const getRandomInt = (max) =>
  Math.floor(Math.random() * Math.floor(max));

export const supplierStatusColors = {
  submitted: 'blue',
  approved: 'blue',
  rejected: 'red',
  registered: 'green',
  'pending-signup': 'orange',
  'pending-approval': 'orange',
  'signed-up': 'orange',
  requested: 'geekblue',
};

export const reqStatusCodes = {
  draft: 'blue',
  rejected: 'red',
  'pending-processing': 'orange',
  'pending-approval': 'orange',
  processing: 'orange',
  completed: 'green',
};

export const reqItemsStatusColors = {
  open: 'orange',
  deleted: 'red',
  'rfq-process': '#cccc00',
  'po-process': '#cccc00',
  completed: 'green',
};

export const frequencyOptions = [
  { id: 1, name: 'One Time' },
  { id: 2, name: 'Daily' },
  { id: 3, name: 'Weekly' },
  { id: 4, name: 'Bi-Weekly' },
  { id: 5, name: 'Monthly' },
  { id: 6, name: 'Quarterly' },
  { id: 7, name: 'Half-Yearly' },
  { id: 8, name: 'Yearly' },
];

export const priceRange = [
  {
    id: 1,
    value: '0-10k',
    label: '0 - 10k',
  },
  {
    id: 2,
    value: '10,001-50k',
    label: '10,001 - 50k',
  },
  {
    id: 3,
    value: '50,000-1L',
    label: '50,001 - 1L',
  },
  {
    id: 4,
    value: '1L-10L',
    label: '1L - 10L',
  },
  {
    id: 5,
    value: '10L+',
    label: '10L+',
  },
];

export const qrStatusCodes = {
  draft: 'orange',
  'pending-approval': 'orange',
  released: 'green',
  pending: 'blue',
  quoted: 'orange',
  negotiation: 'blue',
  requoted: 'orange',
  cancelled: 'red',
  converted: 'green',
  lost: 'red',
  regretted: 'red',
  rejected: 'red',
  'partially-converted': 'green',
};

export const qrCompCodes = {
  'pending-approval': 'orange',
  approved: 'green',
  rejected: 'red',
};

export const poStatusCodes = {
  draft: 'orange',
  released: 'blue',
  accepted: 'blue',
  rejected: 'red',
  invoiced: 'blue',
  'partially-invoiced': 'blue',
  delivered: 'green',
  'partially-delivered': 'green',
  'pending-approval': 'red',
  submitted: 'orange',
  cancelled: 'red',
};

export const typeOfPO = {
  direct: 'grey',
  'rate-contract': 'pink',
  rfq: 'purple',
};

export const invStatusCodes = {
  created: 'blue',
  delivered: 'green',
  cancelled: 'red',
  disputed: 'red',
};

export const proFormaInvoiceStatusCodes = {
  outstanding: 'blue',
  overdue: 'red',
  paid: 'green',
};

export const auctionStatusCodes = {
  pending: 'orange',
  accepted: 'green',
  regretted: 'red',
  'bid-submitted': 'green',
  'prebid-submitted': 'blue',
  'pending-selection': 'orange',
};

export const paymentVoucherStatusColors = {
  pending: 'orange',
  paid: 'green',
};

export const payableStatusColors = {
  outstanding: 'blue',
  overdue: 'red',
  paid: 'green',
};

export const rateContractStatusCodes = {
  draft: 'orange',
  released: 'blue',
  active: 'green',
  rejected: 'red',
  terminated: 'red',
  cancelled: 'red',
  'pending-approval': 'pink',
};

export const pendingInwardStatusCodes = {
  pending: 'orange',
  'partially-inwarded': 'blue',
  inwarded: 'green',
};

export const creditTermsOptions = [
  'Net 15',
  'Net 30',
  'Net 45',
  'Net 60',
  'Net 75',
  'Net 90',
  ADVANCE_100,
  DELIVERY_100,
  'Custom',
];

export const rfqTypeOptions = [
  { id: 1, name: 'One Time' },
  { id: 2, name: 'Consumables' },
];

export const roundTo = (value, digits = 0) => {
  const multiplicator = 10 ** digits;
  const tmp = parseFloat((value * multiplicator).toFixed(5));
  const ret = Math.round(tmp) / multiplicator;
  return +ret.toFixed(digits);
};

export const generateFileExtension = (url) => url.split('.').slice(-1);

export const statusCardStyle = {
  borderRadius: '5px',
};
export const statusCardBodyStyle = (isLoading, color) => {
  const commonStyle = {
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderLeft: `solid 4px ${color}`,
  };
  const loadingStyle = {
    ...commonStyle,
    padding: '2px 16px',
    maxHeight: 62,
    overflowY: 'hidden',
    borderBottom: 'solid 6px #fff',
  };
  const normalStyle = {
    ...commonStyle,
    padding: '16px',
  };
  if (isLoading) {
    return loadingStyle;
  }
  return normalStyle;
};


export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export function countdownTimer(endDate) {
  const endTime = new Date(endDate).getTime();
  const now = new Date().getTime();
  const diff = endTime - now;
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((diff % (1000 * 60)) / 1000);
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  if (diff < 0) {
    return false;
  }
  return [days, hours, minutes, seconds];
}

export const formRequiredRule = (reqMessage = 'Input is mandatory') => ({
  required: true,
  message: reqMessage,
});

export function paymentTermsData() {
  const netDays = [15, 30, 45, 60, 75, 90];
  const data = {};
  netDays.forEach((item) => {
    let index = Object.keys(data).length + 1;
    data[`Net ${item}`] = {
      key: index,
      value: `Net ${item}`,
      payload: {
        advance: 0,
        onDelivery: 0,
        creditItems: [
          {
            days: item,
            percentage: 100,
          },
        ],
      },
    };
    index += 1;
  });
  data[DELIVERY_100] = {
    key: Object.keys(data).length + 1,
    value: DELIVERY_100,
    payload: {
      advance: 0,
      onDelivery: 100,
      creditItems: [],
    },
  };
  data[ADVANCE_100] = {
    key: Object.keys(data).length + 2,
    value: ADVANCE_100,
    payload: {
      advance: 100,
      onDelivery: 0,
      creditItems: [],
    },
  };
  return data;
}

export function pushUrlToObject(fileListData, newFileData) {
  const tempArray = fileListData.toJS();
  const fileData = newFileData;
  fileData.uid = fileData.key;
  fileData.active = 1;
  tempArray.push(fileData);
  return tempArray;
}

export function formatFileData(fileData) {
  return fileData.map((item) => ({
    key: item.url,
    uid: item.url,
    fileName: item.name,
    id: item.id,
    active: item.active,
  }));
}

export function getPaymentTermsInPayloadFormat(
  fieldValue,
  selectedPaymentTerms,
) {
  let finalData = null;
  if (fieldValue === 'Custom') {
    finalData = selectedPaymentTerms;
  } else {
    finalData = paymentTermsData()[fieldValue].payload;
  }
  finalData.key = fieldValue;
  return finalData;
}

export const financialMonths = [
  {
    id: '01',
    value: 'Jan',
  },
  {
    id: '02',
    value: 'Feb',
  },
  {
    id: '03',
    value: 'Mar',
  },
  {
    id: '04',
    value: 'Apr',
  },
  {
    id: '05',
    value: 'May',
  },
  {
    id: '06',
    value: 'Jun',
  },
  {
    id: '07',
    value: 'Jul',
  },
  {
    id: '08',
    value: 'Aug',
  },
  {
    id: '09',
    value: 'Sep',
  },
  {
    id: '10',
    value: 'Oct',
  },
  {
    id: '11',
    value: 'Nov',
  },
  {
    id: '12',
    value: 'Dec',
  },
];

export const token = getToken();

export const arrayToTree = (items, id = null, link = 'parent_category_id') =>
  items
    .filter((item) => item[link] === id)
    .map((item) => ({ ...item, children: arrayToTree(items, item.key) }));

export const BranchList = ['All', 'Corporate Office', 'Registered Office'];

export const statesList = [
  'All',
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
];
