import React from 'react';
import Typography from 'antd/es/typography';

const { Text } = Typography;

type Props = {
  touched: any;
  errors: any;
  name: string;
};

function ErrorMessage({ touched, errors, name }: Props) {
  if (
    name &&
    touched &&
    errors &&
    touched[name] &&
    typeof errors[name] === 'string'
  ) {
    return <Text type="danger">{errors[name]}</Text>;
  }

  if (
    name &&
    touched &&
    errors &&
    touched[name] &&
    typeof errors[name] === 'object' &&
    errors[name].message
  ) {
    return <Text type="danger">{errors[name].message}</Text>;
  }
  return null;
}

export default ErrorMessage;
