import React from 'react';
import { theme } from 'antd';
import { Button as ButtonComponent, type ButtonProps } from '@turbocomplynpm/button';

const { useToken } =  theme
const Button = ({children, ...rest}: ButtonProps) => {
  const token = useToken();

  return (
  <ButtonComponent {...rest} theme={token}>
    {children}
  </ButtonComponent>
  )
}

  export default Button;