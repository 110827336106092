import React from 'react';
import classNames from 'classnames/bind';
import Flex from 'uikit/Flex/Flex';
import { Paragraph } from 'uikit/Typography/Typography';
import * as styles from './verticle-border.module.css';

const cx = classNames.bind(styles);

export function VerticleBorder() {
  return (
    <Flex middle center className={cx('verticle-border')}>
      <div className={cx('content')}>
        <Paragraph color="gray">OR</Paragraph>
      </div>
    </Flex>
  );
}
