import React, { type ReactNode } from 'react';
import { Title, Paragraph } from 'uikit/Typography/Typography';
import classNames from 'classnames/bind';
import LinkWrapper from 'uikit/linkwrapper/linkwrapper';
import Flex from 'uikit/Flex/Flex';
import { SvgTurboComply } from 'icons/svg-turbocomply';
import * as styles from './login-card.module.css';

const cx = classNames.bind(styles);
type LoginCardProps = {
  children: ReactNode;
  title: string;
  registrationLink: string;
};
export function LoginCard({
  children,
  title,
  registrationLink,
}: Readonly<LoginCardProps>) {
  return (
    <Flex center middle className={cx('login')}>
      <SvgTurboComply className={cx('logo')} />
      <Flex center className={cx('login__container')}>
        <div className={cx('card')}>
          <Flex row baseline className={cx('title')}>
            <Title>{title}</Title>
            <Paragraph color="gray" className={cx('description')}>
              See where your compliance stands.
            </Paragraph>
          </Flex>
          {children}
          <div className={cx('footer')}>
            <Paragraph color="gray" align="center">
              Don&apos;t have an account?{' '}
              <LinkWrapper to={registrationLink}>Register</LinkWrapper>
            </Paragraph>
          </div>
        </div>
      </Flex>
    </Flex>
  );
}
