import { fromJS } from 'immutable';
import {
  STORE_DASHBOARD_DETAILS,
  GET_DASHBOARD_DETAILS,
  GET_LOGGED_INFO,
  STORE_LOGGED_INFO,
} from './constants';

const initialState = fromJS({
  details: {},
  pageLoader: true,
  comapnyInfo: {},
});

export default function dashboardDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_DETAILS:
      return state.merge({ pageLoader: true });
    case STORE_DASHBOARD_DETAILS:
      return state.merge({ details: action.data, pageLoader: false });
    default:
      return state;
  }
}

export function getLoggedInfoReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LOGGED_INFO:
      return state.merge({ pageLoader: true });
    case STORE_LOGGED_INFO:
      return state.merge({ comapnyInfo: action.data, pageLoader: false });
    default:
      return state;
  }
}
