import ReactGA from 'react-ga4';

type TrackEventParam = {
  category: string;
  action: string;
  label?: string;
};

const trackEvent = ({ category, action, label }: TrackEventParam) => {
  if (ReactGA.isInitialized) {
    ReactGA.event({
      category,
      action,
      label,
    });
  }
};

export const trackErrorEvent = (errorMessage: string) => {
  trackEvent({
    category: 'ERROR_BOUNDARY',
    action: 'ERROR',
    label: errorMessage,
  });
};
