import { atom } from 'recoil';
import {
  COMPANY_NAME_ATOM,
  FIRM_TENANT_ID,
  STOKEN_ATOM,
} from './dashboard-actions';

export const stokenAtom = atom({
  key: STOKEN_ATOM,
  default: '',
});

export const frimTenantIdAtom = atom({
  key: FIRM_TENANT_ID,
  default: '',
});

export const companyNameAtom = atom({
  key: COMPANY_NAME_ATOM,
  default: '',
});
