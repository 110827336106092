import { fromJS } from 'immutable';
import {
  USER_REGISTERED,
  REGISTER_USER,
  REQUEST_ERROR,
  STORE_EMUNS,
  GET_COMPANIES_DETAILS,
  STORE_COMPANY_DETAILS,
} from './constants';

const registrationInitialState = fromJS({
  registerBtnLoader: false,
  enums: {},
  companyDetails: {},
  companyDetailsLoader: false,
});

export default function registrationReducer(
  state = registrationInitialState,
  action,
) {
  switch (action.type) {
    case REGISTER_USER:
      return state.merge({ registerBtnLoader: true });
    case USER_REGISTERED:
      return state.merge({ registerBtnLoader: false });
    case REQUEST_ERROR:
      return state.merge({ registerBtnLoader: false });
    case STORE_EMUNS:
      return state.merge({ enums: action.enums });
    case GET_COMPANIES_DETAILS:
      return state.merge({ companyDetailsLoader: true });
    case STORE_COMPANY_DETAILS:
      return state.merge({
        companyDetails: action.companyDetails,
        companyDetailsLoader: false,
      });
    default:
      return state;
  }
}
