import { FormType } from 'containers/Signup/store/signup.types';
import { BulkFileDownloadTypes } from 'containers/Categories/atoms/personalfile-types';
import { InvoiceDetailAtomType } from 'proforma-invoices/atoms/proforma-invoices-types';
import { fetchClient } from './api-client';
import {
  URL_MAPPINGS,
  BE_HOST_URL,
  PLATFORM_HOST_URL,
  UserMappingKeysType,
} from '../app-constants';
import { formatUrl } from './helper';
import {
  MonthType,
  UpdateTenantComplianceApiType,
  EditCredientialApiType,
  GetAllEventListPlatfromApiType,
  GetInvoiceHtmlApiType,
  UpdateBranchApiType,
  DownloadFileApiType,
  UpdateAddedUserType,
  CreateCommentsApiParams,
  UpdateCommentsApiParams,
  FetchInvoiceDetailsApiType,
  DeleteTaskFromTemplateApiParams,
} from './api.types';

const getUrl = (key: UserMappingKeysType) =>
  `${BE_HOST_URL}/${URL_MAPPINGS[key]}`;

const getPlatformUrl = (url: string) =>
  formatUrl(url, { prefix: `${PLATFORM_HOST_URL}/api/v1`, postfix: '' });

const getPlatformV2Url = (url: string) =>
  formatUrl(url, { prefix: `${PLATFORM_HOST_URL}/api/v2`, postfix: '' });

const getProformaInvoiceUrl = (url: string) =>
  formatUrl(url, {
    prefix: `${PLATFORM_HOST_URL}/api/v1/proforma_invoices`,
    postfix: '',
  });

const getBaseUrl = (url: string) =>
  formatUrl(url, { prefix: BE_HOST_URL, postfix: '' });
const getAuthBaseUrl = (url: string) =>
  formatUrl(url, { prefix: `${BE_HOST_URL}/auth`, postfix: '' });
const getApiUrl = (url: string) =>
  formatUrl(url, { prefix: `${BE_HOST_URL}/api`, postfix: '' });
const getAdminApiUrl = (url: string) =>
  formatUrl(url, { prefix: `${BE_HOST_URL}/api/admin`, postfix: '' });
const getApiDataRoomUrl = (url: string) =>
  formatUrl(url, { prefix: `${BE_HOST_URL}/api/data_room`, postfix: '' });
const beV2ApiUrl = (url: string) =>
  formatUrl(url, { prefix: `${BE_HOST_URL}/v2/api`, postfix: '' });
const getCrmApiUrl = (url: string) =>
  formatUrl(url, { prefix: `${PLATFORM_HOST_URL}/api/v1/crm`, postfix: '' });

const UPLOAD_FILE = 'upload/data_file';
const USER_URL: UserMappingKeysType = 'USERS';
const GENERIC_TAG_URL = 'generic_tag';
const DELETE_TASK_FILE_URL = 'data_room/task/delete';
const DOWNLOAD_URL = 'download';
const ONBOARD_COMPANY = 'onboard_company';
const PARENT_CATEGORIES = 'parent_categories';
const EDIT_USER = 'user/edit';
const MY_PROFILE_URL = '/users/my_profile';
const PERSONAL_FILE_URL = 'personal/files';
const REGISTERED_BRANCHES = 'registered_branches';
const currentYear = new Date().getFullYear();
const LOGIN_TO_ANOTHER_ACCOUNT = 'login_to_another_account';

export const getGoogleUrlApi = () => getBaseUrl('/google_auth_url');
export const fetchGoogleCallCallbackApi = () =>
  getAuthBaseUrl('/google/callback');
export const fetchDashboardDataApi = () => getAdminApiUrl('/dashboard');
export const fetchCompaniesListApi = () => getPlatformUrl('/companies/list');
export const fetchAllAdminCompaniesApi = () => getAdminApiUrl('/all_companies');
export const getCompanyAccessApi = () => getApiUrl('/company/access');
export const fetchCompaniesDetails = () => getApiUrl('/get_companies_details');
export const fetchFolderApi = () => getApiUrl('/folders');
export const addFolderApi = () => getApiUrl('/folders/new');
export const addFileApi = () => getApiUrl(`/${UPLOAD_FILE}`);
export const addMultipleFileApi = () =>
  getApiUrl('/upload_multiple_files/data_file');
export const fetchAllTasksApi = () => getPlatformUrl('/tasks/aggregated_tasks');
export const fetchAllUsersApi = () => getApiUrl(`/${USER_URL.toLowerCase()}`);

export const fetchGenericTagsApi = () => getAdminApiUrl(`/${GENERIC_TAG_URL}`);
export const fetchMyCompanyApi = () => getApiUrl('/my_company');
export const fetchMyProfileApi = () => getPlatformUrl(MY_PROFILE_URL);
export const deleteTaskFileApi = () => getApiUrl(`/${DELETE_TASK_FILE_URL}`);

export const downloadFilesApi = () => getApiUrl(`/${DOWNLOAD_URL}`);

export const onboardCompanyApi = () => getApiUrl(`/${ONBOARD_COMPANY}`);

export const listCommentsApi = (taskId: string) =>
  getPlatformUrl(`/comments/${taskId}/list`);

export const createReviewersApi = (tenantId: string) =>
  getPlatformUrl(`/reviewers/${tenantId}/upsert`);

export const createCommentsApi = ({
  taskId,
  tenantId,
}: CreateCommentsApiParams) =>
  getPlatformUrl(`/comments/${taskId}/${tenantId}/create`);

export const createCommentsFilesListApi = (taskId: string) =>
  getPlatformUrl(`/comments/${taskId}/files/list`);

export const deleteCommentApi = (commentId: string) =>
  getPlatformUrl(`/comments/${commentId}/delete`);

export const editCommentApi = ({
  taskId,
  commentId,
  tenantId,
}: UpdateCommentsApiParams) =>
  getPlatformUrl(`/comments/${taskId}/${tenantId}/${commentId}/update`);

export const getAllTasksPlatformApi = () => getPlatformUrl('/tasks/list_all');
export const listActivitylogsApi = (taskId: string) =>
  getPlatformUrl(`/activitylogs/${taskId}/list`);

export const getAllEventListPlatfromApi = ({
  categoryId,
  tenantId,
}: GetAllEventListPlatfromApiType) =>
  getPlatformUrl(`/category_tree/${categoryId}/${tenantId}/events`);

export const getEventAssociationPlatformApi = (event_id: string) =>
  getPlatformUrl(`/admin/generic_event/${event_id}/get_event_association`);

// TODO: remove dup
export const newupdateBranchApi = (branch_id: string) =>
  getApiUrl(`branch/${branch_id}/update`);
export const closeSubtaskApi = (taskId: string) =>
  getApiUrl(`sub_task/${taskId}/close`);
export const getTaskFilesApi = (taskId: string) =>
  getApiDataRoomUrl(`/tasks/${taskId}/folder/files`);
export const updateMyProfileApi = () => getApiUrl('/profile/update');
export const getParentCategoryApi = () => getApiUrl(PARENT_CATEGORIES);
export const updateFolderApi = () => getApiUrl('/folders/update');
export const updateFileApi = () => getApiUrl('/files/update');
export const deleteFolderApi = () => getApiUrl('/folders/bulk_delete');
export const deleteFileApi = () => getApiDataRoomUrl('/bulk_delete');
export const personalFilesApi = () =>
  getApiDataRoomUrl(`/${PERSONAL_FILE_URL}`);
export const getNewAllFilesApi = (
  financial_quater: string,
  category_id: string,
) => {
  if (financial_quater && category_id) {
    return getApiDataRoomUrl(
      `/category/${financial_quater}/${category_id}/folder/files`,
    );
  }
  if (financial_quater) {
    return getApiDataRoomUrl(`/folder/${financial_quater}/files`);
  }
  if (category_id) {
    return getApiDataRoomUrl(`/category/${category_id}/folder/files`);
  }

  return getApiDataRoomUrl(`/folder/files`);
};
export const getDashboardApi = (tenantId: string | undefined) => {
  if (tenantId) {
    return getPlatformUrl(`/dashboard/${tenantId}/list`);
  }
  return getPlatformUrl(`/dashboard/list`);
};
export const getDashboardFilterApi = (tenantId: string | undefined) => {
  if (tenantId) {
    return getPlatformUrl(`/dashboard/${tenantId}/filter`);
  }
  return getPlatformUrl('/dashboard/filter');
};

export const getNewFolderApi = () => getApiDataRoomUrl(`/folders`);

export const trashFoldersApi = () => getApiUrl('/folders');
export const restoreFileApi = (file_id: string) =>
  getApiDataRoomUrl(`${file_id}/restore`);
export const bulkMoveApi = () => getApiUrl('/folders/bulk_move');

export const updateCustomTaskApi = (id: string) =>
  getApiUrl(`/task/${id}/update_custom_task`);
export const bulkFolderCreateApi = () => getApiUrl('/folders/bulk_create');
export const restoreFolderApi = (folder_id: string) =>
  getApiUrl(`/folders/${folder_id}/restore`);

export const fetchBookmarkListApi = () => getApiUrl('bookmarks/list');
export const updateBookmarkApi = () => getApiUrl('/bookmarks/create');
export const deleteBookmarkApi = () => getApiUrl('/bookmarks/delete');
export const reuploadFileApi = () => getApiUrl('/files/reupload/data_file');
export const updateComplianceApi = (complianceId: string) =>
  getAdminApiUrl(`/generic_compliance/${complianceId}/edit`);
export const updateEventsApi = (key: string) =>
  getAdminApiUrl(`/generic_event/${key}/edit`);
export const getAllEventApi = () => getAdminApiUrl('/generic_event');
export const updateFolderReplaceApi = () => getApiUrl('/folders/replace');
export const updateGenericTaskApi = (taskId: string) =>
  getAdminApiUrl(`generic_task/${taskId}/edit`);
export const customTaskPlatformApi = () =>
  getPlatformUrl('/tasks/custom_task/new');
export const getNotificationsApi = () => getPlatformUrl('/notifications/list');
export const updateNotificationReadStatusApi = () =>
  getPlatformUrl('/notifications/status/read');
export const createLabelApi = () => getApiUrl('labels/new');
export const listLabelApi = (tenantId: string) =>
  getApiUrl(`/labels/${tenantId}/list_labels`);
export const editLabelApi = (id: string) => getApiUrl(`labels/${id}/update`);
export const deleteLabelApi = (id: string) => getApiUrl(`labels/${id}`);
export const editUserProfile = () => getApiUrl(`/${EDIT_USER}`);
export const taskListByIdApi = (taskId: string) =>
  getPlatformUrl(`/tasks/${taskId}/details`);
export const createInvoiceApi = (tenantId: string) =>
  getPlatformUrl(`/invoices/${tenantId}/invoice_create`);
export const updateInvoiceApi = (tenantId: string, id: string | undefined) =>
  getPlatformUrl(`/invoices/${tenantId}/update/${id}`);
export const getFirmConfigsApi = (tenantId: string) =>
  getPlatformUrl(`/firm_configs/${tenantId}/list`);
export const createFirmConfigsApi = () =>
  getPlatformUrl(`/firm_configs/create`);

export const uploadInvoiceSignatureApi = (tenantId: string) =>
  getPlatformUrl(`/invoices/${tenantId}/upload_signature`);
export const removeSignatureApi = (tenantId: string) =>
  getPlatformUrl(`/invoices/${tenantId}/signatures/delete`);
export const getPaymentAccountsApi = (tenantId: string) =>
  getPlatformUrl(`/payment_accounts/${tenantId}/list`);
export const createPaymentAccountsApi = (tenantId: string) =>
  getPlatformUrl(`/payment_accounts/${tenantId}/create`);
export const updatePaymentAccountsApi = (tenantId: string, accountId: string) =>
  getPlatformUrl(`/payment_accounts/${tenantId}/update/${accountId}`);
export const listPaymentTransactionApi = (
  tenantId: string,
  invoiceId: string,
) =>
  getPlatformUrl(
    `/invoices/${tenantId}/payment_transactions/${invoiceId}/list`,
  );
export const logoPathUploadApi = () =>
  getPlatformUrl(`/firm_configs/upload_logo`);
export const listInvoiceApi = (tenantId: string) =>
  getPlatformUrl(`/invoices/${tenantId}/invoice_list`);
export const fetchInvoiceDetailsApi = ({
  tenantId,
  invoiceId,
}: FetchInvoiceDetailsApiType) =>
  getPlatformUrl(`/invoices/${tenantId}/show/${invoiceId}`);
export const recordPaymentApi = (tenantId: string) =>
  getPlatformUrl(`/invoices/${tenantId}/payment_transactions/create`);

export const listGroupsApi = () => getPlatformUrl('/firm_settings/list_groups');
export const deleteGroupApi = (groupId: string) =>
  getPlatformUrl(`/firm_settings/${groupId}/delete_group`);
export const createGroupsApi = () =>
  getPlatformUrl('/firm_settings/create_group');
export const updateGroupsApi = (groupId: string) =>
  getPlatformUrl(`/firm_settings/${groupId}/update_group`);

export const fetchAllAssigneeUsersApi = (tenantId: string) =>
  getPlatformUrl(`/${tenantId}/assignee_list`);
export const createRolesApi = () => getPlatformUrl(`/tc_roles/create`);
export const editRolesApi = (roleId: string) =>
  getPlatformUrl(`/tc_roles/${roleId}/update`);
export const deleteRolesApi = (roleId: string) =>
  getPlatformUrl(`/tc_roles/${roleId}/delete`);
export const assignUsersForRoleApi = (roleId: string) =>
  getPlatformUrl(`/tc_roles/${roleId}/assign_users`);
export const listRolesApi = () => getPlatformUrl(`/tc_roles`);
export const resourcesApi = () => getPlatformUrl('/resources');
export const resourceByIdApi = (resourceId: string) => {
  if (resourceId) {
    return getPlatformUrl(`/resources/${resourceId}/list`);
  }
  return getPlatformUrl(`/resources`);
};

export const getRolesDetailsApi = (roleId: string) =>
  getPlatformUrl(`/tc_roles/${roleId}/details`);

export const getResourcesWithPermissionsApi = () =>
  getPlatformUrl('/resources/list_all');

export const listUserManagementApi = () =>
  getPlatformUrl('/firm_settings/users_list');

export const listAssignedCompaniesApi = () => getPlatformUrl(`/companies_list`);

export const createUserManagementApi = () =>
  getPlatformUrl('/firm_settings/add_users');
export const editUserManagementApi = (userId: string) =>
  getPlatformUrl(`/firm_settings/${userId}/edit_user`);
export const deleteUserManagementApi = (userId: string) =>
  getPlatformUrl(`/firm_settings/${userId}/delete_user`);
export const assignCompaniesUserManagementApi = () =>
  getPlatformUrl(`/update_assign_companies`);
export const getRegisteredBranchesApi = () => getApiUrl(REGISTERED_BRANCHES);
export const removeAssigneeFromRoleApi = (roleId: string) =>
  getPlatformUrl(`/tc_roles/${roleId}/remove_assignee`);
export const createTimeSheetApi = (tenantId: string) =>
  getPlatformUrl(`/firm_configs/${tenantId}/working_hours/create`);
export const branchupsertApi = () =>
  getPlatformUrl(`/settings/branches/upsert`);
export const companyupsertApi = () =>
  getPlatformUrl(`/settings/company_info/upsert`);
export const getInvoiceHtmlApi = ({
  type,
  tenantId,
  invoiceId,
}: GetInvoiceHtmlApiType) =>
  getPlatformUrl(`/${type}/${tenantId}/${invoiceId}/print`);
export const updateCustomTaskPlatformApi = (taskId: string) =>
  getPlatformUrl(`/tasks/${taskId}/update_custom_task`);

export const bulkAssigneeApi = () => getPlatformUrl('/bulk_assignee_list');
export const getWorkLogsApi = (
  taskId: string,
  tenantId: string,
  firmTenantId: string,
) => getPlatformUrl(`/worklogs/${taskId}/${tenantId}/${firmTenantId}/details`);

export const addWorkLogsApi = (taskId: string, tenantId: string) =>
  getPlatformUrl(`/worklogs/${taskId}/${tenantId}/create`);

export const listWorkingHoursApi = (tenantId: string) =>
  getPlatformUrl(`/firm_settings/working_hours/${tenantId}/view`);

export const getTaskWorkLogsApi = (tenantId: string) =>
  getPlatformUrl(`/tasks/${tenantId}/worklogs/list`);
export const getTimerApi = () => '/get_time';
export const addCredientialApi = (tenantId: string) =>
  getPlatformUrl(`/credentials/${tenantId}/create`);
export const editCredientialApi = ({ tenantId, id }: EditCredientialApiType) =>
  getPlatformUrl(`/credentials/${tenantId}/${id}/update`);
export const getCredientialTemplateDetailsApi = (id: string) =>
  getPlatformUrl(`credential_templates/${id}/details`);
export const getIndividualCredentialApi = (
  tenantId: string,
  credentialId: string,
) =>
  getPlatformUrl(
    `/credentials/${tenantId}/${credentialId}/list_credential_data`,
  );

export const getCredientialTemplateListApi = () =>
  getPlatformUrl(`/credential_templates/list`);
export const authenticateVaultPasswordApi = () =>
  getPlatformUrl('/vault/authenticate/password');
export const verifyVaultAuthenticationApi = () =>
  getPlatformUrl('/vault/authenticate/verify');

export const getCredentialsListApi = (tenant_id: string) =>
  getPlatformUrl(`/credentials/${tenant_id}/list`);

export const deleteCredentialsList = () =>
  getPlatformUrl('/credential/bulk/delete');
export const markAsExpiredCredentialsList = () =>
  getPlatformUrl('/credential/bulk/mark_as_expired');

export const notificationTwoFactorApi = () =>
  getPlatformUrl(`/vault/notifications/two_factor_authentication`);

export const twoFactorAuthenticationApi = () =>
  getPlatformUrl(`/vault/authenticate/two_factor_code`);

export const getFormTemplateApi = (countryCode: string, type: FormType) =>
  getPlatformUrl(`/settings/form_template/${countryCode}/${type}/details`);
export const getCountryDetailsApi = (countryCode: string) =>
  getPlatformUrl(`/settings/country/${countryCode}/details`);

export const verifyTwoFactorAuthenticationApi = () =>
  getPlatformUrl('/vault/notifications/two_factor/email_verify');
export const loginToAnotherAccount = (token: string) =>
  getAdminApiUrl(`/${LOGIN_TO_ANOTHER_ACCOUNT}/${token}`);
export const listProformaInvoicesApi = (tenantId: string) =>
  getProformaInvoiceUrl(`/${tenantId}/list`);
export const createProformaInvoiceApi = (tenantId: string) =>
  getProformaInvoiceUrl(`/${tenantId}/create`);

export const deleteInvoiceAttachmentsApi = (tenantId: string) =>
  getPlatformUrl(`/invoices/${tenantId}/attachment/delete`);
export const listProformaInvoiceDetailsApi = ({
  tenantId,
  proformaInvoiceId,
}: InvoiceDetailAtomType) =>
  getProformaInvoiceUrl(`/${tenantId}/show/${proformaInvoiceId}`);
export const shareInvoiceEmailApi = ({
  type,
  tenantId,
  invoiceId,
}: GetInvoiceHtmlApiType) =>
  getPlatformUrl(`/${type}/${tenantId}/share/${invoiceId}`);
export const getBranchesListApi = (tenantId: string) =>
  getPlatformUrl(`/branches/${tenantId}/list`);
export const getBankInfoApi = () => getPlatformUrl('/bank_details/list');
export const upsertCrmLayoutApi = () => getCrmApiUrl('/widget_upsert');
export const listCrmWidgetsApi = () => getCrmApiUrl('/widget_list');
export const listCredentialsApi = () => getPlatformUrl('/credentials/list');
export const createTaskTemplateApi = () => getPlatformUrl('/templates/create');
export const listTaskTempalteApi = () => getPlatformUrl('/templates/list');
export const loginApi = () => getApiUrl('/login');
export const createTaskFromTemplateApi = () =>
  getPlatformUrl('/templates/create/tasks');
export const deleteTaskFromTemplateApi = ({
  taskTempalteId,
}: DeleteTaskFromTemplateApiParams) =>
  getPlatformUrl(`/templates/${taskTempalteId}/delete`);
export const getTemplateDetailsApi = ({
  taskTempalteId,
}: DeleteTaskFromTemplateApiParams) =>
  getPlatformUrl(`/templates/${taskTempalteId}/details`);
export const updateTaskTemplateApi = ({
  taskTempalteId,
}: DeleteTaskFromTemplateApiParams) =>
  getPlatformUrl(`/templates/${taskTempalteId}/update`);
export const getGoogleLoginUrlApi = () => `${BE_HOST_URL}/google_auth_url`;

const getFromApi = (
  key: UserMappingKeysType,
  postFix = '',
  params = {},
  headers = {},
) => {
  const url = `${getUrl(key)}/${postFix}`;
  return fetchClient('normal', {
    headers,
  }).get(url, { params });
};

const postToApi = (
  key: UserMappingKeysType,
  postFix = '',
  data = {},
  headers = {},
) => {
  const url = `${getUrl(key)}/${postFix}`;
  return fetchClient('normal', {
    headers,
  }).post(url, data);
};

const putToApi = (
  key: UserMappingKeysType,
  postFix = '',
  data = {},
  headers = {},
) => {
  const url = `${getUrl(key)}/${postFix}`;
  return fetchClient('normal', {
    headers,
  }).put(url, data);
};

const delToApi = (
  key: UserMappingKeysType,
  postFix = '',
  data = {},
  headers = {},
) => {
  const url = `${getUrl(key)}/${postFix}`;
  return fetchClient('normal', {
    headers,
  }).delete(url, data);
};

const authPostToApi = (key: UserMappingKeysType, postFix = '', data = {}) => {
  const url = `${getUrl(key)}/${postFix}`;
  return fetchClient('normal').post(url, data);
};

const docUploadPostToApi = (
  key: UserMappingKeysType,
  postFix = '',
  data = {},
) => {
  const url = `${getUrl(key)}/${postFix}`;
  return fetchClient('upload').post(url, data);
};

export const registerUserApi = (data: string) =>
  authPostToApi('API', 'register', data);

export const getParentCategoriesApi = () =>
  getFromApi('API', PARENT_CATEGORIES);

export const getEnumsApiRegistration = () =>
  getFromApi('API', 'enums?register=true');

export const getCategoriesApi = (categoryId: string) =>
  getFromApi('API', `category_tree/${categoryId}`);

export const getTaxationApi = (data: string, categoryId: string) =>
  postToApi('API', `tasks/${categoryId}`, data);

export const addCategoryApi = (data: string) =>
  postToApi('ADMIN', 'generic_categories/new', data);

export const getFlattenedCompliancesApi = () =>
  getFromApi('ADMIN', 'flattened_compliances');

export const deleteGeneComIdApi = (complianceId: string) =>
  delToApi('ADMIN', `generic_compliance/${complianceId}`);

export const deleteEventsApi = (key: string) =>
  delToApi('ADMIN', `generic_event/${key}/delete`);

export const getCategoryTreeApi = () => getFromApi('ADMIN', 'category_tree');

export const getComplianceTasksApi = (complianceId: string, data: string) =>
  postToApi('TASKS', `compliance/${complianceId}`, data);

export const getTaskDetailsApi = (taskId: string) =>
  getFromApi('TASKS', taskId);

export const closeChecklistApi = (uuid: string, data: string) =>
  postToApi('CHECKLISTS', `${uuid}/close`, data);

export const getMyCompanyDetailsApi = () => getFromApi('API', 'my_company');

export const getUserRolesApi = () => getFromApi('API', 'roles');

export const createUserApi = () => getApiUrl('/users/add');

export const getMyprofileApi = () => getPlatformUrl(MY_PROFILE_URL);

export const getBulDownloadApi = ({ tenantId }: BulkFileDownloadTypes) =>
  getPlatformUrl(`/folders/${tenantId}/bulk_download`);

export const updateCompanyProfileApi = (data: string) =>
  putToApi('API', 'my_company/update', data);

export const createLocationApi = (data: string) =>
  postToApi('API', 'branches/new', data);

export const updateProfileApi = (data: string) =>
  putToApi('API', 'profile/update', data);

export const editUserProfileApi = (data: string, stoken: string) =>
  putToApi('API', EDIT_USER, data, { stoken });

export const deleteUserProfileApi = (UserId: string, stoken: string) =>
  delToApi('API', `user/delete/${UserId}`, {}, { stoken });

export const getAllUsersApi = (stoken: string) =>
  getFromApi(USER_URL, '', {}, { stoken });

export const getLoogedInfoApi = (tenantId: string) =>
  getFromApi('ADMIN', `get_info/${tenantId}`);

export const getAllLocationsApi = () => getFromApi('API', REGISTERED_BRANCHES);

export const addComplianceApi = (data: string) =>
  postToApi('GENERIC_COMPLIANCES', 'new', data);

export const createNewEventApi = (data: string) =>
  postToApi('ADMIN', 'generic_event/new', data);

export const startEventsApi = () => getApiUrl('/events/start');

export const getAllEventListApi = (categoryId: string) =>
  getFromApi('API', `category_tree/event/${categoryId}`);

export const getAllEventListSearchApi = (categoryId: string) =>
  getFromApi('API', `category_tree/${categoryId}/events`);

export const getAllEventListCompletedApi = (categoryId: string) =>
  getFromApi('API', `category_tree/${categoryId}/events/true`);

export const getAllEventListActiveApi = (categoryId: string) =>
  getFromApi('API', `category_tree/${categoryId}/events/false`);

export const getAllEventDependenciesApi = () =>
  getFromApi('ADMIN', 'generic_event/dependencies');

export const getCompliancesApi = (categoryId: string) =>
  getFromApi('API', `compliances/${categoryId}`);

export const getGenericTasksApi = (complianceId: string) =>
  getFromApi('ADMIN', `${complianceId}/generic_tasks`);

export const updateCategoryApi = (categoryId: string) =>
  getAdminApiUrl(`generic_category/${categoryId}/edit`);

export const createTaskApi = (data: string) =>
  postToApi('ADMIN', 'generic_tasks/new', data);

export const getAllTasksApi = () => getFromApi('TASKS', 'all');

export const getAllUpcomingTasksApi = () => postToApi('TASKS', 'upcoming');

export const getAllTasksNewApi = (data: string) =>
  postToApi('TASKS', 'list_all', data);

export const getCategoryTasksApi = (categoryId: string, data: string) =>
  postToApi('API', `tasks/${categoryId}`, data);

export const getMyTasksApi = (data: string) => postToApi('TASKS', 'my', data);

export const getMyLocationApi = (data: string) =>
  postToApi('API', 'tasks/list_all', data);

export const getOverDueTaskApi = (data: string) =>
  postToApi('TASKS', 'overdue', data);

export const getUpcomingTaskApi = (data: string) =>
  postToApi('TASKS', 'upcoming', data);

export const getSubTasksApi = (taskId: string) =>
  getFromApi('API', `sub_tasks/${taskId}`);

export const createNewTaskApi = (data: string) =>
  postToApi('API', 'tasks/custom_task/new', data);

export const deleteSubTaskApi = (subTaskId: string) =>
  delToApi('API', `sub_task/${subTaskId}/delete`);

export const createSubTaskApi = (data: string, taskId: string) =>
  postToApi('API', `task/${taskId}/sub_task/new`, data);

export const getDashboardDetailsApi = (tenant_id: string) =>
  getFromApi('API', `dashboard/${tenant_id}/overview`);

export const createHelpTicketApi = (data: string) =>
  postToApi('API', 'sps/query', data);

export const getUploadUrlApi = (data: string) =>
  docUploadPostToApi('API', UPLOAD_FILE, data);

export const removeDataRoomFileApi = (data: string) =>
  putToApi('API', `data_room/${data}/delete`);

export const getFilesApi = (month: MonthType, complianceId: string) =>
  getFromApi(
    'API',
    `data_room/${complianceId}/folder/files?month=${month.monthId}&year=${month.year}`,
  );

export const createDataRoomEntryApi = (data: string) =>
  postToApi('API', 'data_room/new', data);

export const UploadRepoApi = (data: string) =>
  postToApi('API', 'data_room/compliance/new', data);

export const getDataRoomDetailsApi = (complianceId: string) =>
  getFromApi('API', `data_room/${complianceId}/folders`);

export const closeSubTaskApi = (data: string, subTaskId: string) =>
  putToApi('API', `sub_task/${subTaskId}/close`, data);

export const getActiveCompliancesApi = (branchId: string) =>
  getFromApi('API', `compliances/${branchId}/active`);

export const downloadFileApi = (data: DownloadFileApiType) =>
  postToApi('API', DOWNLOAD_URL, data);

export const getCategoryRepositoryApi = (categoryId: string) =>
  getFromApi('API', `data_room/category/folders/${categoryId}`);

export const getCategoryFilesApi = (month: MonthType, categoryId: string) =>
  month.monthId
    ? getFromApi(
        'API',
        `data_room/category/${categoryId}/folder/files?month=${month.monthId}&year=${month.year}`,
      )
    : getFromApi(
        'API',
        `data_room/category/annual/${categoryId}/folder/files?year=${month.year}`,
      );

export const getCategoryAllFilesApi = (month: MonthType) =>
  month.monthId
    ? getFromApi(
        'API',
        `data_room/folder/files?month=${month.monthId}&year=${month.year}`,
      )
    : getFromApi('API', `data_room/folder/annual/files?year=${month.year}`);
export const getDataRoomFoldersApi = () =>
  getFromApi('API', 'data_room/folders');

export const updateTenantComplianceApi = ({
  data,
  complianceId: generic_compliance_id,
}: UpdateTenantComplianceApiType) =>
  putToApi('API', `compliances/${generic_compliance_id}/update`, data);

export const getDataRoomTasksApi = (task_id: string) =>
  getFromApi('API', `data_room/tasks/${task_id}/folder/files`);

export const updateDataRoomInvalidateApi = (data: string) =>
  postToApi('API', DELETE_TASK_FILE_URL, data);

export const updateBranchApi = ({ data, branch_id }: UpdateBranchApiType) =>
  putToApi('API', `branch/${branch_id}/update`, data);

export const getGenericTagApi = () => getFromApi('ADMIN', GENERIC_TAG_URL);

export const addGenericTagApi = (data: string) =>
  postToApi('ADMIN', 'generic_tag/new', data);

export const editGenericTagApi = (tagId: string, data: string) =>
  putToApi('ADMIN', `generic_tag/${tagId}/edit`, data);

export const deleteGenericTagApi = (tagId: string) =>
  delToApi('ADMIN', `generic_tag/${tagId}/delete`);

export const closeTaskApi = (task_id: string, data: string) =>
  putToApi('API', `task/${task_id}/close`, data);

export const getQuaterFilesApi = (
  month: MonthType,
  year: string,
  category_id: string,
) =>
  getFromApi(
    'API',
    `data_room/category/frequency/${category_id}/folder/files?month=${month}&year=${year}`,
  );

export const getHalfYearlyFilesApi = (
  month: MonthType,
  year: string,
  category_id: string,
) =>
  getFromApi(
    'API',
    `data_room/category/half_yearly/${category_id}/folder/files?month=${month}&year=${year}`,
  );

export const getYearlyAllFilesApi = (month: MonthType, year: string) =>
  getFromApi(
    'API',
    `data_room/folder/half_yearly/files?month=${month}&year=${year}`,
  );

export const getQuarterlyAllFilesApi = (month: MonthType, year: string) =>
  getFromApi(
    'API',
    `data_room/folder/quarterly/files?month=${month}&year=${year}`,
  );

export const getAnnualFilesApi = (year: string, category_id: string) =>
  getFromApi(
    'API',
    `data_room/category/annual/${category_id}/folder/files?year=${year}`,
  );

export const getAnnualAllFilesApi = (year: string) =>
  getFromApi('API', `data_room/folder/annual/files?year=${year}`);

export const forgotPasswordApi = (data: string) =>
  postToApi('API', 'forgot_password', data);

export const updatePasswordApi = (data: string) =>
  postToApi('API', 'update_password', data);

export const getCompaniesDetailsApi = (name: string) =>
  postToApi('API', `get_companies_details/?name=${name}`);

export const getLoginToAnotherAccountApi = (token: string) =>
  getFromApi('ADMIN', `${LOGIN_TO_ANOTHER_ACCOUNT}/${token}`);

export const RegisterNewUserApi = (data: string) =>
  postToApi('API', 'register_new_user', data);

export const companyAccessApi = (data: string) =>
  postToApi('API', 'company/access', data);

export const getPendingAccessList = () =>
  getFromApi('API', 'get_pending_access_list');

export const updateAccessApi = (data: string) =>
  putToApi('API', 'update_access_request', data);

export const getEventAssociationApi = (event_id: string) =>
  getFromApi('ADMIN', `generic_event/${event_id}/get_event_association`);

export const getPersonalFilesApi = () =>
  getFromApi('API', `data_room/${PERSONAL_FILE_URL}?year=${currentYear}`);

// TODO: can be changed to format url method.
export const onboardingCompany = (data: string) =>
  postToApi('API', ONBOARD_COMPANY, data);

export const updateAddedUser = (data: UpdateAddedUserType) =>
  putToApi('API', 'user/update_added_user', data);

export const signUpApi = (data: string) => postToApi('API', 'signup', data);

export const newSignUpApi = (data: string) =>
  postToApi('V2API', 'signup', data);

export const verifyUserApi = (data: string) =>
  postToApi('API', 'verify/otp', data);

export const registerAuditorApi = (data: string) =>
  postToApi('V2API', 'register_company', data);

export const resendOtpApi = (data: string) =>
  postToApi('API', 'resend/otp', data);

export const registerCompanyApi = (data: string) =>
  postToApi('API', 'register_company', data);

export const searchEventApi = (data: string) =>
  putToApi('API', 'events/search_event', data);

export const registerTenantApi = () => beV2ApiUrl('/register_company');
export const newUserSignUpApi = () => beV2ApiUrl('/signup');
export const getSafeVaultSettingsApi = () =>
  getPlatformUrl('/vault/settings/security_details');

export const resetVaultPasswordMailApi = () =>
  getPlatformUrl('/vault/notification/reset_password');
export const resetVaultPasswordUpdateApi = () =>
  getPlatformUrl('/firm_configs/vault_password_update');

export const crmTableLayoutDisplayApi = () => getCrmApiUrl(`/widget_list`);
export const crmOpenInvoicesApi = () => getCrmApiUrl(`/open_invoices`);
export const crmCredentialsApi = () => getCrmApiUrl(`/credentials`);

export const getCompanyDetailsApi = (tenantId: string) =>
  getPlatformUrl(`/companies/${tenantId}/info`);

export const updateTaskLabelApi = (id: string) =>
  getPlatformUrl(`/tasks/label/${id}/edit`);

export const updateNewCustomTaskPlatformApi = (taskId: string) =>
  getPlatformV2Url(`/tasks/${taskId}/update`);

export const closeSubtaskPlatformApi = (id: string) =>
  getPlatformUrl(`/tasks/sub_task/${id}/close`);

export const createSubtaskPlatformApi = ({
  tenantId,
  taskId,
}: CreateCommentsApiParams) =>
  getPlatformUrl(`/tasks/${tenantId}/sub_task/${taskId}/new`);

export const upsertRulesPlatformApi = () => getPlatformUrl(`/rules/upsert`);

export const updateTaskStatePlatformApi = (taskId: string) =>
  getPlatformV2Url(`/tasks/${taskId}/update_task_state`);

export const deleteTasksPlatformApi = () => getPlatformUrl('tasks/task/delete');
