import { BranchesEntity } from 'branches/atoms/branches-types';
import { DATE_FORMAT } from 'app-constants';
import { getDateString } from 'utils/helper';
import { IconColorType } from 'icons/icons.types';
import {
  ALL_TASKS_PERMISSION,
  MY_TASKS_PERMISSION,
} from 'containers/Authorize/authorize-constant';
import { isPermissionPresent } from 'containers/Authorize/authorize-helper';
import {
  AssignedTo,
  ReviewerType,
  FormatedTaskType,
  TaskPathBasedPermissionTypes,
} from './task.types';

export const isDueDatePayloadPresent = (
  isCalendar: boolean,
  due_date?: string[],
) => isCalendar && Array.isArray(due_date) && due_date.length === 0;

export const getFormatedBranches = (branches: BranchesEntity[]) =>
  branches.map((branch) => ({
    ...branch,
    country_code: branch.country_code,
    zipcode: branch.zipcode ?? '',
    address1: branch.address1 ?? '',
    address2: branch.address2 ?? '',
    city: branch.city ?? '',
    state: branch.state ?? '',
    phone: branch.phone ?? '',
    email: branch.email ?? '',
    gst: branch.gst ?? '',
    is_hq: branch.is_hq ?? false,
    branch_info: branch.branch_info ?? { zone: '', zone_type: '' },
    branch_type: branch.branch_type,
    id: branch.id,
    company_id: branch.company_id ?? '',
    is_active: branch.is_active ?? '',
    country: branch.country ?? '',
    name: branch.name ?? '',
    countryCode: branch.countryCode ?? '',
  }));

export const formatTaskUpdatePayload = (allData: FormatedTaskType) => {
  const formattedDate = getDateString(allData.due_date, DATE_FORMAT);
  const mappedLabelIds = allData.related_labels
    ? allData.related_labels.map((relatedLabel) => relatedLabel.id)
    : [];
  const labelIds = mappedLabelIds.length > 0 ? mappedLabelIds : undefined;
  const originalEstimate = allData.original_estimate ?? undefined;
  const billableType =
    allData.extra_field && allData.extra_field.billable_type
      ? allData.extra_field.billable_type
      : undefined;
  const assignedTo = allData.assigned_to ? allData.assigned_to.id : undefined;
  const completed = allData.completed || allData.state === 'completed';

  return {
    title: allData.title,
    description: allData.description,
    due_date: formattedDate,
    severity: allData.severity,
    assigned_to: assignedTo,
    label_id: labelIds,
    billable_type: billableType,
    original_estimate: originalEstimate,
    completed,
  };
};

export const getSeverity = (severity: string): IconColorType => {
  switch (severity) {
    case 'high':
      return 'redmaterial';
    case 'medium':
      return 'yellow';
    default:
      return 'gray';
  }
};

export const getReviewersData = (
  term: ReviewerType,
  users?: AssignedTo[],
): AssignedTo => {
  const defaultUserDict = {
    name: '',
    email: '',
    id: '',
    extra_fields: {
      color: '',
    },
  };
  if (!Array.isArray(users)) {
    return defaultUserDict;
  }

  const foundUser = users.find((user) => user.term === term) || {};

  return {
    ...defaultUserDict,
    ...foundUser,
  };
};

export const getTaskAssigneePayload = (
  permissions: string[],
  userId: string,
) => {
  if (permissions.includes(MY_TASKS_PERMISSION)) {
    return {
      assignee: userId,
    };
  }
  return { assignee: undefined };
};

export const getTaskPathBasedPermission = ({
  permissions,
  tenantId,
}: TaskPathBasedPermissionTypes) => {
  let taskPath = '';
  const myTaskPermission = isPermissionPresent({
    permissions,
    type: MY_TASKS_PERMISSION,
  });
  const allTaskPermission = isPermissionPresent({
    permissions,
    type: ALL_TASKS_PERMISSION,
  });
  if (myTaskPermission) {
    taskPath = `/my-tasks/${tenantId}`;
  }
  if (allTaskPermission) {
    taskPath = `/all-tasks/${tenantId}`;
  }
  return taskPath;
};
