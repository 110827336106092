import { fromJS } from 'immutable';
import {
  LOGGED_IN,
  SESSION_EXPIRED,
  STORE_USER_DATA,
  LOG_IN,
} from './constants';

const authIntitialState = fromJS({
  authenticated: false,
  isRegistered: false,
  userDetails: {},
  accesses: [],
  loginBtnLoader: false,
  authToken: null,
  // authEmail:null
});

export default function loginReducer(state = authIntitialState, action) {
  console.log('loginReducer', action);
  switch (action.type) {
    case LOG_IN:
      return state.merge({
        authenticated: false,
        loginBtnLoader: true,
        isRegistered: false,
      });
    case LOGGED_IN:
      return state.merge({
        authenticated: action.authenticated,
        userDetails: action.userDetails,
        authToken: action.authToken,
        // authEmail: action.authEmail,
        isRegistered: action.isRegistered,
        loginBtnLoader: false,
      });
    case 'REQUEST_ERROR':
      return state.merge({ loginBtnLoader: false, isRegistered: false });
    case SESSION_EXPIRED:
      return state.merge({ authenticated: false, isRegistered: false });
    case STORE_USER_DATA:
      return state.merge({ userDetails: action.data, authenticated: true });
    default:
      return state;
  }
}
