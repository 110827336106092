import * as Colors from './color-constants';

export const generateCssColorVars = () => {
  if (typeof Window === 'undefined') {
    return;
  }
  let style = '';
  const colors = { ...Colors } as any;
  const root = document.querySelector(':root');
  Object.keys(colors).forEach((colorKey: string) => {
    if (colorKey in colors) {
      if (!colorKey.includes('space') || colorKey.includes('spacemicro')) {
        style += `--${colorKey}:${colors[colorKey]};`;
      } else {
        const [spaceName, spaceIndex] = colorKey.split('_');
        style += `--${spaceName}-${spaceIndex}:${colors[colorKey]};`;
      }
    }
    return null;
  });
  if (root) {
    root.setAttribute('style', style);
  }
};
