import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllTasksNewApi } from '../../utils/api';

export const taskListThunkAction = createAsyncThunk(
  'FETCH_TASK_LIST',
  async (data: any) => {
    try {
      return (await getAllTasksNewApi(data)) as any;
    } catch (error) {
      const typedError = error as Error;
      throw new Error(typedError.message);
    }
  },
);
