import { fromJS } from 'immutable';
import {
  STORE_COMPANY_LIST,
  GET_COMPANY_LIST,
  COMPANY_SWITCHED,
  STORE_EMUNS,
} from './constants';

const initialState = fromJS({
  companyList: {},
  allcompanyList: {},
  pageLoader: true,
  pageAllLoader: true,
  onboardData: {},
});

export default function companyListReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_COMPANY_LIST:
      return state.merge({ pageLoader: true });
    case STORE_COMPANY_LIST:
      return state.merge({ companyList: action.data, pageLoader: false });
    case STORE_EMUNS:
      return state.merge({ enums: action.enums });
    case COMPANY_SWITCHED:
      return state.merge({
        authenticated: action.authenticated,
        userDetails: action.userDetails,
        authToken: action.authToken,
      });
    default:
      return state;
  }
}
