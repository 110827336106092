import {
  FoldersChildrenEntity,
  BreadcrumbItem,
} from 'containers/Categories/atoms/personalfile-types';
import moment from 'moment';
import Message from 'uikit/Message/Message';
import { UploadFile } from 'uikit/UploadDragger/upload-dragger';
import { getDaysBetween } from '@turbocomplynpm/date-utils';
import {
  ACCOUNT_TOKEN,
  SESSION_EXPIRY_DAYS,
  TOKEN,
  SUPER_TOKEN,
  ROLE,
  TENANT_ID,
  COMPANY_LIST,
  COMPANY_NAME,
  FIRM_TENANT_ID,
  LOCALE_CONSTANT,
  VAULT_COOKIES,
} from '../app-constants';
import { isEmpty } from './validators';

export const setCookie = (cname: string, cvalue: string, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/;secure;samesite=strict;`;
};

export const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

interface GetDayAfterParams {
  dateString?: string;
  after?: number;
}

export const getDayAfter = ({ dateString, after = 0 }: GetDayAfterParams) => {
  if (!dateString) {
    return moment().add(after, 'day');
  }
  return moment(dateString).add(1, 'day');
};

export const getEndOfDay = (dateString?: string) => {
  if (!dateString) {
    return moment().endOf('day');
  }
  return moment(dateString).endOf('day');
};

export const getMomentDateLocal = (dueDate?: string) =>
  dueDate ? moment.parseZone(dueDate).local() : undefined;

export const checkIsDateAfterPresent = (date: moment.Moment) => {
  const today = date.startOf('day');
  const currentDate = moment();
  return currentDate.isAfter(today);
};

export const getToken = () => getCookie(TOKEN);
export const getAccountToken = () => getCookie(ACCOUNT_TOKEN);

export const swapToken = () => {
  const accountToken = getAccountToken();
  const token = getToken();
  if (accountToken) {
    setCookie(TOKEN, accountToken, SESSION_EXPIRY_DAYS);
  }
  if (token) {
    setCookie(ACCOUNT_TOKEN, token, SESSION_EXPIRY_DAYS);
  }
};

export const swapSuperTokenToToken = () => {
  const superToken = getCookie(SUPER_TOKEN);
  if (superToken) {
    setCookie(TOKEN, superToken, SESSION_EXPIRY_DAYS);
  }
};

type SetSessionParamType = {
  token: string;
  role: string;
  tenantId: string;
};

export const setSession = ({ token, role, tenantId }: SetSessionParamType) => {
  setCookie(TOKEN, token, SESSION_EXPIRY_DAYS);
  setCookie(ACCOUNT_TOKEN, token, SESSION_EXPIRY_DAYS);
  setCookie(SUPER_TOKEN, token, SESSION_EXPIRY_DAYS);
  setCookie(ROLE, role, SESSION_EXPIRY_DAYS);
  setCookie(TENANT_ID, tenantId, SESSION_EXPIRY_DAYS);
};

export const removeUserSession = () => {
  setCookie(TOKEN, '', 0);
  setCookie(ACCOUNT_TOKEN, '', 0);
  setCookie(SUPER_TOKEN, '', 0);
  setCookie(ROLE, '', 0);
  setCookie(TENANT_ID, '', 0);
  setCookie(COMPANY_LIST, '', 0);
  setCookie(COMPANY_NAME, '', 0);
  setCookie(FIRM_TENANT_ID, '', 0);
  setCookie(VAULT_COOKIES.IS_OTP_SETUP_COMPLETED, '', 0);
  setCookie(VAULT_COOKIES.IS_TWO_FACTOR_SETUP_COMMPLETED, '', 0);
  setCookie(VAULT_COOKIES.IS_TWO_FACTOR_VERIFIED, '', 0);
};

export const formatUrl = (
  url: string,
  options: { prefix?: string; postfix?: string } = { postfix: '.json' },
) => {
  if (!url) return '';
  let formattedUrl = url.charAt(0) === '/' ? url : `/${url}`;
  let queryString = '';
  if (formattedUrl.includes('?')) {
    const [urlPartOne, urlPartTwo] = formattedUrl.split('?');
    formattedUrl = urlPartOne;
    queryString = `?${urlPartTwo}`;
  }
  const postfixVal =
    typeof options.postfix !== 'undefined' ? options.postfix : '.json';
  const preFixVal = options.prefix ? options.prefix : '';
  return preFixVal + formattedUrl + postfixVal + queryString;
};

export type DateType =
  | 'MMM DD, YYYY'
  | 'DD/MM/YYYY'
  | 'DD MMM YYYY'
  | 'DD MMM'
  | 'DD MMM YYYY, hh:mm a'
  | 'DD MMM, YYYY'
  | 'MM/DD/YYYY'
  | 'YYYY/MM/DD'
  | 'YYYY-MM-DD'
  | 'ddd, MMM DD, YYYY hh:mm A'
  | 'DD/MM/YY, hh:mm a'
  | 'LT'
  | 'LTS';

export type TimeUnit =
  | 'year'
  | 'years'
  | 'y'
  | 'month'
  | 'months'
  | 'M'
  | 'week'
  | 'weeks'
  | 'w'
  | 'day'
  | 'days'
  | 'd'
  | 'hour'
  | 'hours'
  | 'h'
  | 'minute'
  | 'minutes'
  | 'm'
  | 'second'
  | 'seconds'
  | 's'
  | 'millisecond'
  | 'milliseconds'
  | 'ms';

export type DateFormatOptionsType = {
  isUnix?: boolean;
  convertToLocal?: boolean;
};

export const getDateString = (
  value: string | number,
  format: DateType,
  options: DateFormatOptionsType = {
    convertToLocal: true,
  },
) => {
  const { isUnix, convertToLocal } = options || {};
  if (
    (typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'object') &&
    !Array.isArray(value) &&
    !isEmpty(format)
  ) {
    if (isUnix) {
      return moment.unix(Number(value)).format(format);
    }
    if (convertToLocal) return moment.parseZone(value).local().format(format);
    return moment.parseZone(value).format(format);
  }
  return '';
};

export const DEFAULT_FOLDER_STRUCT: FoldersChildrenEntity = {
  children: [],
  key: '',
  title: '',
  uploaded_at: '',
};

export const DEFAULT_BREADCRUMB_STRUCT: BreadcrumbItem = {
  key: '',
  title: '',
};

export const DEFAULT_MYFILE_BREADCRUMB_STRUCT: BreadcrumbItem = {
  key: '',
  title: 'My Files',
};

export const getTreeNode = (
  folders: FoldersChildrenEntity[],
  key: string,
): FoldersChildrenEntity[] => {
  let result: FoldersChildrenEntity[] = [];

  for (let i = 0; i < folders.length; i += 1) {
    const folder = folders[i];

    if (folder && folder.key === key) {
      return folder.children ? folder.children : [folder];
    }

    if (
      folder &&
      Array.isArray(folder.children) &&
      folder.children.length > 0
    ) {
      result = result.concat(getTreeNode(folder.children, key));
    }
  }

  return result;
};

export const getTreeNodePath = (
  folders: FoldersChildrenEntity,
  key: string,
): BreadcrumbItem[] => {
  let result: BreadcrumbItem[] = [{ ...DEFAULT_BREADCRUMB_STRUCT }];
  if (folders && folders.key === key) {
    result = [folders];
  } else if (
    folders &&
    Array.isArray(folders.children) &&
    folders.children.length > 0
  ) {
    for (let i = 0; i < folders.children.length; i += 1) {
      result = getTreeNodePath(folders.children[i], key);
      if (result && result.length > 0 && result[result.length - 1].key !== '') {
        result = [folders as BreadcrumbItem].concat(result);
        break;
      }
    }
  }
  return result;
};

const COLORS = ['#c8c8ee', '#cbeec8', '#f7f1b6', '#ffc8c8'];

export function getRandomColor(colors: string[] = COLORS): string {
  return colors[Math.floor(Math.random() * colors.length)];
}


export const showErrorMessage = (error: unknown) => {
  if (error instanceof Error) {
    Message.error(error.message);
    throw new Error(error.message);
  }
  throw new Error('Something went wrong');
}

export function showToast(status: string, message: string ) {
  if (typeof message === 'string' && status === 'error') {
    Message.error(message);
  }
  if (typeof message === 'string' && status === 'ok') {
    Message.success(message);
  }
}

export function getFixedColors(length: number) {
  const hueValues = Array.from({ length }, (_, i) => (i * 12) % 360);
  const saturation = 80;
  const lightness = 50;
  return hueValues.map((hue) => `hsl(${hue} ${saturation}% ${lightness}% )`);
}

export const addTransparencyToHslColors = (color: string) => {
  const newColor = color;
  return newColor.replace(/(50%\s\)|50%\))$/, '50% / 20%)').replace(/,/g, '');
};

export const removeDuplicateArray = (arr: string[]) => [...new Set(arr)];

export const pipe =
  <T>(...fns: Array<(arg: T) => T>) =>
  (value: T) =>
    fns.reduce((acc, fn) => fn(acc), value);

const formatTimeAgo = (value: number, unit: string): string => {
  const formattedValue = Math.floor(value);
  return `${formattedValue} ${unit}${
    formattedValue === 1 || formattedValue === 0 ? '' : 's'
  } ago`;
};

export const TimeAgo = (eventDate: Date | string): string => {
  const currentDate = moment();
  const formattedEventDate = moment(eventDate);
  const duration = moment.duration(currentDate.diff(formattedEventDate));

  if (duration.asSeconds() < 60) {
    return formatTimeAgo(Math.ceil(duration.asSeconds()), 'second');
  }
  if (duration.asMinutes() < 60) {
    return formatTimeAgo(Math.floor(duration.asMinutes()), 'minute');
  }
  if (duration.asHours() < 24) {
    return formatTimeAgo(Math.floor(duration.asHours()), 'hour');
  }
  if (duration.asDays() < 7) {
    return formatTimeAgo(Math.floor(duration.asDays()), 'day');
  }
  if (duration.asWeeks() < 4) {
    return formatTimeAgo(Math.floor(duration.asWeeks()), 'week');
  }
  if (duration.asMonths() < 12) {
    return formatTimeAgo(Math.floor(duration.asMonths()), 'month');
  }
  return formatTimeAgo(Math.floor(duration.asYears()), 'year');
};

export const isNumber = (value: string | number): boolean => {
  if (typeof value === 'string' || typeof value === 'number') {
    return value.toString().match(/^\d*$/) !== null;
  }
  return false;
};
export const checkisNumber = (value: string): boolean => !Number.isNaN(value);
export const handleCatchErrorBlock = (error: unknown) => {
  const typedError = error as Error;
  Message.error(typedError.message);
  return new Error(typedError.message);
};

export const getCurrentDate = () => moment().utc().format();

export const copyToClipboard = async (text: string) =>
  navigator.clipboard.writeText(text);

export const getUtcDate = (value: moment.Moment, format?: DateType) =>
  format ? value.utc().format(format) : value.utc().format();

export const getMomentDate = (value?: string | Date) =>
  value ? moment(value) : moment();

export function convertToBlob(file: UploadFile): File | null {
  return file.originFileObj
    ? new File([file.originFileObj], file.name, { type: file.type })
    : null;
}

export async function toBase64(url: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function formatToSnakeCase(inputString: string) {
  const lowercaseString = inputString.toLowerCase();
  return lowercaseString.replace(/\s+/g, '_');
}
export const calculateDuration = (durationString: string) => {
  const milliSeconds = Number(durationString) * 60 * 1000;
  const duration = moment.duration(milliSeconds);

  const hours = Math.floor(duration.asHours());
  duration.subtract(hours, 'hours');

  const minutes = Math.floor(duration.asMinutes());
  duration.subtract(minutes, 'minutes');

  const seconds = Math.round(duration.asSeconds());

  return {
    hours,
    minutes,
    seconds,
  };
};

export const getStartOfDate = (
  date: Date | string,
  unit: TimeUnit,
  format?: DateType,
) => getMomentDate(date).startOf(unit).utc().format(format);

export const getEndOfDate = (
  date: Date | string,
  unit: TimeUnit,
  format?: DateType,
) => getMomentDate(date).endOf(unit).utc().format(format);

export const getRoundOffValue = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

const getFormattingCountryCode = (countryCode: string) => {
  switch (countryCode) {
    case 'IN':
    case 'AE':
      return LOCALE_CONSTANT[countryCode];
    default:
      return LOCALE_CONSTANT.IN;
  }
};
export const getLocaleBasedOnCountryCode = (countryCode: string) => {
  if (countryCode) {
    return getFormattingCountryCode(countryCode);
  }
  return 'en-IN';
};

type DateDiffType = {
  startDate: Date | string;
  endDate: Date | string;
  options?: TimeUnit;
};
export const getDateDiff = ({
  startDate,
  endDate,
  options = 'seconds',
}: DateDiffType) =>
  getDaysBetween({
    startDate,
    endDate,
    options,
  });
