import React, { ReactNode, Suspense, useEffect, useState } from 'react';
import { useLocation } from 'utils/router';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import type {
  ErrorBoundaryType,
  ResetKeysType,
} from '../ErrorBoundary/ErrorBoundary.types';
import { SuspenseDefaultProps } from './suspense-loader-types';
import LoaderWidget from './loader-widget';

const defaultProps: SuspenseDefaultProps = {
  loaderType: 'spinner',
};

type Props = {
  children: ReactNode;
  loaderClass?: string;
  testId?: string;
} & ErrorBoundaryType &
  SuspenseDefaultProps;

function SuspenseLoader({
  children,
  reset,
  resetKeys,
  loaderType,
  loaderClass,
  testId,
}: Readonly<Props>) {
  const [resetKeysState, setResetKeysState] = useState<
    ResetKeysType[] | undefined
  >(resetKeys);
  const { pathname } = useLocation();
  useEffect(() => {
    if (typeof resetKeys === 'undefined') {
      setResetKeysState([pathname]);
    } else {
      setResetKeysState([...resetKeys, pathname]);
    }
  }, [pathname, resetKeys]);

  return (
    <ErrorBoundary reset={reset} resetKeys={resetKeysState}>
      <Suspense
        fallback={
          <LoaderWidget
            loaderType={loaderType}
            loaderClass={loaderClass}
            testId={testId}
          />
        }>
        <>{children}</>
      </Suspense>
    </ErrorBoundary>
  );
}

SuspenseLoader.defaultProps = defaultProps;
export default SuspenseLoader;
