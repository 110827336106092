import { createSlice } from '@reduxjs/toolkit';
import { taskListThunkAction } from './tasklistthunks';

const initialState = {
  isLoading: false,
  error: '',
  data: [],
};

// pending, fulfilled, rejected
const tasklistSlice = createSlice({
  initialState,
  name: 'tasklistreducer',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(taskListThunkAction.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(taskListThunkAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = '';
      Object.assign(state, action.payload);
    });
    builder.addCase(taskListThunkAction.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? 'Failed to fetch';
    });
  },
});

const tasklistreducer = tasklistSlice.reducer;
export default {
  tasklistreducer,
};
