import { fromJS } from 'immutable';
import {
  STORE_ALL_LAW_EVENT,
  STORE_ALL_SEARCH_EVENT,
  STORE_START_EVENT,
  STORE_ALL_COMPLETED_EVENT,
  STORE_ALL_ACTIVE_EVENT,
  START_LOADING,
  STORE_EVENT_ASSOCIATION,
  STORE_EVENT_MASTER,
} from './constants';

const initialState = fromJS({
  tasksTableLoader: true,
  genericTasks: {},
  EventData: [],
  parent_category_name: null,
  isCatTreeChanged: false,
  isEventChanged: false,
  loadingCompiances: false,
});

export default function EventReducer(state = initialState, action = {}) {
  switch (action.type) {
    case STORE_EVENT_MASTER:
      return state.merge({
        EventData: action.data,
        pageLoader: false,
      });
    case STORE_ALL_LAW_EVENT:
      return state.merge({
        EventData: action.data,
        pageLoader: false,
        parent_category_name: action.parent_category_name,
      });
    case START_LOADING:
      return state.merge({ loadingCompiances: true });
    case STORE_ALL_SEARCH_EVENT:
      return state.merge({
        eventsSearch: action.data,
        isEventChanged: true,
        loadingCompiances: false,
      });
    case STORE_ALL_COMPLETED_EVENT:
      return state.merge({
        eventsCompleted: action.data,
        isEventChanged: true,
        loadingCompiances: false,
      });
    case STORE_ALL_ACTIVE_EVENT:
      return state.merge({
        eventsActive: action.data,
        isEventChanged: true,
        loadingCompiances: false,
      });
    case 'SEARCH_EVENT_FECTH_STARTED':
      return state.merge({ searchLoading: true });
    case 'SEARCH_EVENT_SUCCESS':
      return state.merge({
        searchLoading: false,
        searchEventList: action.data,
      });
    case STORE_START_EVENT:
      return state.merge({
        eventsStart: action.data,
        loadingCompiances: false,
      });
    case STORE_EVENT_ASSOCIATION:
      return state.merge({
        eventAssociation: action.data,
        loadingCompiances: false,
      });
    default:
      return state;
  }
}
