import React, { type ReactNode, createContext } from 'react';
import { MyProfileResponse } from 'containers/account-settings/Atom/account-settings.types';

export const MyProfileContext = createContext<MyProfileResponse>({
  my_profile: {
    email: '',
    first_name: '',
    last_name: '',
    profile_image: '',
    country_code: '',
    type: '',
    user_id: '',
    dob: '',
  },
  permissions: {
    names: [],
    tenants: [],
  },
  subscription: {},
  status: '',
});

type Props = {
  myProfile: MyProfileResponse;
  children: ReactNode;
};

const MyProfileProvider = ({ myProfile, children }: Props) => {
  return (
    <MyProfileContext.Provider value={myProfile}>
      {children}
    </MyProfileContext.Provider>
  );
};

export default MyProfileProvider;
